import React from 'react';
import { Container } from 'react-bootstrap';
import './downloadApp.css'

export default function DownloadApp() {

    function redirectToGoogleApps() {
        window.open("https://play.google.com/store/apps/details?id=com.agel")
    }


    return (
        <div className='download-app-container text-center' id="downloadApp">

            <div className='download-app-content-container'>
                {/* header text  */}
                <Container className='d-grid website-generic-font'>

                    <span className='download-app-title'>حمل التطبيق الآن</span>

                    <span className='download-app-subtitle mt-3 d-grid'>
                        <span className='mt-1'>
                            آجل شريك تقدر تـثـق فيه لتوفير البضاعة اللي محتاجها لتجارتك
                        </span>
                        <span className='mt-1'>
                            التعامل مع آجـل بسيط ومريح لأنك مش هتحتاج حساب بنكي ولا معاملات معقدة
                        </span>
                    </span>

                </Container>

                {/* play store app image download  */}
                <div>
                    <img src='/images/play-store-logo-download-app-screen.png' alt="play-store-app-img-download" className='img-fluid download-app-logo-download-screen' onClick={redirectToGoogleApps} />
                </div>

            </div>
        </div>
    )
}



