import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import './FQA.css'

export default function FQA() {

    // for specific start carousel item
    const [index, setIndex] = useState(3);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className='FQA website-generic-font' id="contactUs">

            <div className='FQA-container'>

                {/* text header */}
                <div className='d-grid FQA-title-container'>
                    <span className='FQA-title'>الأسئلة الشائعة</span>
                </div>

                {/* form/image */}
                <div className='text-center mt-4 agel-carousel-container' >
                    <div cl className='agel-carousel-left'>
                        <img src="/images/FQA.svg" alt="FQA-img" className="agel-carousel-img" />
                    </div>

                    <div className='agel-carousel-right' lang="en" dir="ltr">
                        <Carousel controls={false} activeIndex={index} onSelect={handleSelect}>

                            {/* item1 */}
                            <Carousel.Item className=" carousel-image">
                                <img
                                    className="d-block w-100 img-fluid white-bg-carousel"
                                    src="/images/carousel-img.jpg"
                                    alt="First slide"
                                />
                                <Carousel.Caption dir="rtl">
                                    <div className="d-grid">
                                        <span className='carousel-question'>
                                            هل آجل هتقدم خدمة نظام مدفوعات مالية شامل ولا نظام خاص بالتجار بس ؟
                                        </span>
                                        <span className='carousel-answer'>
                                            سيتم تزويد الأجهزة بالقدرة على دفع الخدمات (قريبا) زي الكهرباء والمياه من خلال الأجهزة وتحويل الأموال عن طريق المحافظ المالية وبعض الخدمات الاخرى.
                                        </span>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>


                            {/* item2 */}
                            <Carousel.Item className=" carousel-image">
                                <img
                                    className="d-block w-100 img-fluid white-bg-carousel"
                                    src="/images/carousel-img.jpg"
                                    alt="First slide"
                                />
                                <Carousel.Caption dir="rtl">
                                    <div className="d-grid">
                                        <span className='carousel-question'>
                                            هل آجل بتستهدف تجارة الملابس بس؟
                                        </span>
                                        <span className='carousel-answer'>
                                            التركيز الحالي لآجل على تجارة الملابس والاكسسوارات و نتوسع في مجالات اخرى قريباً.
                                        </span>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>


                            {/* item3 */}
                            <Carousel.Item className=" carousel-image">
                                <img
                                    className="d-block w-100 img-fluid white-bg-carousel"
                                    src="/images/carousel-img.jpg"
                                    alt="First slide"
                                />
                                <Carousel.Caption dir="rtl">
                                    <div className="d-grid">
                                        <span className='carousel-question'>
                                            هل آجل بتقدم خدمة مابعد البيع والصيانة في حالة وجود مشكلة في جهاز نقطة البيع؟
                                        </span>
                                        <span className='carousel-answer'>
                                            آجل تقوم  بتقديم الدعم الكامل لشركائها التجار في حالة تعطل الأجهزة من خلال الهاتف, وفي حالة عدم حل المشكلة يتم تحديد زيارة للتاجر لحل المشكلة في اسرع وقت.
                                        </span>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>


                            {/* item4 */}
                            <Carousel.Item className=" carousel-image">
                                <img
                                    className="d-block w-100 img-fluid white-bg-carousel"
                                    src="/images/carousel-img.jpg"
                                    alt="First slide"
                                />
                                <Carousel.Caption dir="rtl">
                                    <div className="d-grid">
                                        <span className='carousel-question'>
                                            يعنى اية مرابحة اسلامية ؟
                                        </span>
                                        <span className='carousel-answer'>
                                            نظام المرابحة الاسلامية يعني جميع الإجراءات تتم من خلال عقود بيع وشراء بين تجار ( الجملة / التجزئة ) وشركة آجل . (متفق عليها بما في ذلك هامش الربح المتفق عليه مسبقاً )
                                        </span>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>


                            {/* item5 */}
                            <Carousel.Item className=" carousel-image">
                                <img
                                    className="d-block w-100 img-fluid white-bg-carousel"
                                    src="/images/carousel-img.jpg"
                                    alt="First slide"
                                />
                                <Carousel.Caption dir="rtl">
                                    <div className="d-grid">
                                        <span className='carousel-question'>
                                            آجل متواجد فين ؟
                                        </span>
                                        <span className='carousel-answer'>
                                            متواجدين في القاهرة والإسكندرية , هدفنا نجمع كل تجار مصر في تطبيق واحد ونقدم لهم البضاعة المناسبة لاحتياجات تجارتهم.
                                        </span>
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>

                        </Carousel>
                    </div>
                </div>

            </div>

        </div>
    )
}