import React from 'react'
import { Container } from 'react-bootstrap'
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import './Zammit.css'


export default function Zammit() {
    return (

        <Container className='zammitWebPage'>

            {/* logo img */}
            <div className='mt-4 d-flex justify-content-center align-items-center'>
                <img src="/webPageAppsImages/zammit_logo.png" alt="zammit-logo" className="zammit-Web-Page-logo" />
            </div>

            {/* header */}
            <p className="zammit-header">
                بيع على الانترنت دلوقتي مع زامت شريك آجل لبناء متجرك الالكتروني:
            </p>


            {/* content */}

            <ul className='zammit-list'>
                <li>
                    موجود واجهة بالعربي والانجليزي ليك عشان تتحكم في موقعك.
                </li>
                <li>
                    وكمان واجهة بالعربي والانجليزي للعملاء عشان الموقع يكون باللغة الي  تناسبه.
                </li>
                <li>
                    ربط تام مع شركات شحن، يعني بتتعامل مع واجهة واحدة بس بتشوف الاوردر وتطبع البوليصة وتشوف خطوات الاوردر، مش مجرد نقل الاوردر لسيستم شركة الشحن وبس .
                </li>
                <li>
                    مفيش نسبة على المبيعات.
                </li>
                <li>
                    تقدر تربط  مخزونك في المحل بمخزون المتجر الإلكتروني.
                </li>
                <li>
                    فريق كامل بيساعدك تبني الموقع بتاعك وياخدك بإيديك خطوة خطوة لحد ما تبدا تشتغل وتبيع .
                </li>
                <li>
                    فريق كامل للدعم معاك يساعدك في أي مشكلة .
                </li>
            </ul>

            <p className="zammit-section">
                تقدر تاخد خصم عملاء آجل وتقسط القيمة النهائية لاشتراك المتجر من خلال تطبيق آجل
            </p>

            {/* zammit links section*/}
            <div class="about-zammit-link zammit-section">
                <p>
                    تقدر تعرف اكتر عن زامت من خلال <a href="https://www.zammit.shop/ar" target="_blank" rel="noopener noreferrer" className='zammit-unstyled-links'>Zammit</a>
                </p>
            </div>

            {/* footer */}
            <div className='zammit-footer-link'>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSeRIStdCdC1AvWWuvFv5LsVKGk1SFeG1rHb7KuPc2x8AMd-NA/viewform' target="_blank" rel="noopener noreferrer" className="zammit-unstyled-links">
                    سجل الان
                    <IoArrowBackCircleSharp className='zammit-footer-link-arrow' />
                </a>

            </div>

        </Container >
    )
}