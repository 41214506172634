import React, { useState, useEffect } from "react";
import { Navbar, Container, Offcanvas, Nav } from "react-bootstrap";
import './Navbar.css'
import { Link, animateScroll as scroll } from "react-scroll";

export default function GlobalNavbar() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const [show, setShow] = useState(false);

    const [selectHomeScreen,setSelectHomeScreen] = useState(true)
    const [selectAboutAgel,setSelectAboutAgel] = useState(false)
    const [selectWhyAgel,setSelectWhyAgel] = useState(false)
    const [selectHowToUseAgel,setSelectHowToUseAgel] = useState(false)
    const [selectContactUs,setSelectContactUs] = useState(false)
    const [selectDownloadApp,setSelectDownloadApp] = useState(false)
    const [selectFQA,setSelectFQA] = useState(false)


    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
        }
        )

    }, [])



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const ShowSelectedItem=(selectedItem)=>{
        handleClose();
        if(selectedItem==="selectHomeScreen"){
            setSelectHomeScreen(true);
            setSelectAboutAgel(false);
            setSelectWhyAgel(false);
            setSelectHowToUseAgel(false);
            setSelectContactUs(false);
            setSelectDownloadApp(false);
            setSelectFQA(false);
        }
        else if(selectedItem==="selectAboutAgel"){
            setSelectHomeScreen(false);
            setSelectAboutAgel(true);
            setSelectWhyAgel(false);
            setSelectHowToUseAgel(false);
            setSelectContactUs(false);
            setSelectDownloadApp(false);
            setSelectFQA(false);
        }
        else if(selectedItem==="selectWhyAgel"){
            setSelectHomeScreen(false);
            setSelectAboutAgel(false);
            setSelectWhyAgel(true);
            setSelectHowToUseAgel(false);
            setSelectContactUs(false);
            setSelectDownloadApp(false);
            setSelectFQA(false);
        }
        else if(selectedItem==="selectHowToUseAgel"){
            setSelectHomeScreen(false);
            setSelectAboutAgel(false);
            setSelectWhyAgel(false);
            setSelectHowToUseAgel(true);
            setSelectContactUs(false);
            setSelectDownloadApp(false);
            setSelectFQA(false);
        }
        else if(selectedItem==="selectContactUs"){
            setSelectHomeScreen(false);
            setSelectAboutAgel(false);
            setSelectWhyAgel(false);
            setSelectHowToUseAgel(false);
            setSelectContactUs(true);
            setSelectDownloadApp(false);
            setSelectFQA(false);
        }
        else if(selectedItem==="selectDownloadApp"){
            setSelectHomeScreen(false);
            setSelectAboutAgel(false);
            setSelectWhyAgel(false);
            setSelectHowToUseAgel(false);
            setSelectContactUs(false);
            setSelectDownloadApp(true);
            setSelectFQA(false);
        }
        else if(selectedItem==="selectFQA"){
            setSelectHomeScreen(false);
            setSelectAboutAgel(false);
            setSelectWhyAgel(false);
            setSelectHowToUseAgel(false);
            setSelectContactUs(false);
            setSelectDownloadApp(false);
            setSelectFQA(true);
        }
    }


    return (
        <div id="homeScreen">
            <Navbar expand="lg" className="main-navbar website-generic-font">
                <Container>
                    <Navbar.Brand className="nav-brand">
                        <img src="/images/navbar-logo-img.png" alt="navbar-logo" className="img-fluid navbar-logo-img" />
                    </Navbar.Brand>

                    <Navbar.Toggle onClick={handleShow} />

                    <Navbar.Offcanvas show={show} onHide={handleClose}
                    >
                        <Offcanvas.Header closeButton variant="white">
                        </Offcanvas.Header>

                        <Container className="text-center">
                            {
                                windowWidth <= 991 ? (
                                    <img src="/images/navbar-logo-img.png" alt="navbar-logo" className="img-fluid navbar-logo-img" />
                                ) : null
                            }
                        </Container>
                        <Offcanvas.Body>

                            <Nav className="me-auto">
                                <Link to="homeScreen">
                                    <Nav.Link className={selectHomeScreen?`nav-items website-generic-font selectNavItem`:`nav-items website-generic-font`} onClick={()=>{ShowSelectedItem("selectHomeScreen")}}>
                                        الرئيسية
                                    </Nav.Link>
                                </Link>
                                <Link to="aboutAgel">
                                    <Nav.Link className={selectAboutAgel?`nav-items website-generic-font selectNavItem`:`nav-items website-generic-font`} onClick={()=>{ShowSelectedItem("selectAboutAgel")}}>
                                        من نحن
                                    </Nav.Link>
                                </Link>
                                <Link to="whyAgel">
                                    <Nav.Link className={selectWhyAgel?`nav-items website-generic-font selectNavItem`:`nav-items website-generic-font`} onClick={()=>{ShowSelectedItem("selectWhyAgel")}}>
                                        ليه آجل
                                    </Nav.Link>
                                </Link>
                                <Link to="howToUseAgel">
                                    <Nav.Link className={selectHowToUseAgel?`nav-items website-generic-font selectNavItem`:`nav-items website-generic-font`} onClick={()=>{ShowSelectedItem("selectHowToUseAgel")}}>
                                        كيف تعمل آجل
                                    </Nav.Link>
                                </Link>
                                {/* <Link to="contactUs">
                                    <Nav.Link className={selectContactUs?`nav-items website-generic-font selectNavItem`:`nav-items website-generic-font`} onClick={()=>{ShowSelectedItem("selectContactUs")}}>
                                        راسلنا
                                    </Nav.Link>
                                </Link> */}
                                <Link to="downloadApp">
                                    <Nav.Link className={selectDownloadApp?`nav-items website-generic-font selectNavItem`:`nav-items website-generic-font`} onClick={()=>{ShowSelectedItem("selectDownloadApp")}}>
                                        حمل التطبيق
                                    </Nav.Link>
                                </Link>
                                <Link to="FQA">
                                    <Nav.Link className={selectFQA?`nav-items website-generic-font selectNavItem`:`nav-items website-generic-font`} onClick={()=>{ShowSelectedItem("selectFQA")}}>
                                        الأسئلة الشائعة
                                    </Nav.Link>
                                </Link>

                            </Nav>
                        </Offcanvas.Body>

                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
} 