import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './aboutAgel.css'

export default function AboutAgel() {
    return (
        <div className='about-agel-container' dir="ltr" id="aboutAgel">

            {/* colored grey top part */}
            <div className='about-agel-top-colored-part'></div>


            {/* white main part */}
            <Row>


                {/* phone image section */}
                <Col xs={12} lg="6" className='about-agel-container-img'>
                    <img src="./images/aboutAgelImg.png" alt="about agel" className='img-fluid about-agel-img' />
                </Col>


                {/* text section */}
                <Col xs={12} lg="6" className='about-agel-text-container'>

                    <Container className='d-grid about-agel-content-container'>

                        {/* title */}
                        <p className='about-agel-title website-generic-font'>
                            شركة آجل لخدمات الدفع الإلكترونى
                        </p>

                        {/* text */}
                        <span className='about-agel-text website-generic-font' style={{ marginBottom: "2rem" }}>
                            تم تأسيس شركة أجل على ايد خبراء في مجال تطوير الأعمال والتمويل والتكنولوجيا والتصنيع
                            لأكثر من 50 سنة  من الخبرة.
                        </span>

                        <span className='about-agel-text website-generic-font' style={{ marginBottom: "2rem" }}>
                        ليه اسم آجل ؟ الأجل كلمة بنسمعها كتير بين التجار و نظام متعارف عليه بينهم  .
                        </span>

                        <span className='about-agel-text website-generic-font' style={{ marginBottom: "2rem" }}>
                        كلمة (آجل ) كلمة شائعة وأسلوب ونظام  نشأ  من زمان بين التجار تسهيل المعاملات التجارية  علشان كدة اخترنا اسم (آجل ) علشان  نكون اسمنا شريك التجار في  معاملاتهم . تطبيق يسهل وينظم معاملاتك بين جميع تجار الجملة والتجزئة بسهولة ومن غير تعقيد . 
                        </span>

                        <span className='about-agel-text website-generic-font' style={{ marginBottom: "2rem" }}>
                        آجل بتوفر لتجار التجزئة البضاعة اللي هتحتاجها لمشروعك ومش بس كده هتسدد على دفعات بسهولة ومن غير أى أجراءات معقدة .
                        </span>

                        <span className='about-agel-text website-generic-font'>
                        آجل بتساعد تجار الجملة في تنظيم معاملاتهم التجارية و بتقدم حلول لتوفير سيولة لتجارتهم .
                        </span>

                    </Container>

                </Col>


            </Row>

        </div>
    )
}