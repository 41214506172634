import React from 'react';

//components
import AboutAgel from './aboutAgel/aboutAgel';
import DownloadApp from './downloadApp/downloadApp';
import Footer from './footer/footer';
import HomeScreen from './homeScreen/homeScreen';
import HowToUseAgel from './HowToUseAgel/HowToUseAgel';
import WhyAgel from './WhyAgel/WhyAgel';
import GlobalNavbar from '../components/Navbar/Navbar';
import ApplyForm from './applyForm/applyForm';
import FQA from './FQA/FQA';



export default function Layout(){
    return(
        <div>
            {/* all screens well be put here */}
            <GlobalNavbar/>
            <HomeScreen/>
            <AboutAgel/>
            <WhyAgel/>
            <HowToUseAgel/>
            {/* <ApplyForm/> */}
            <DownloadApp/>
            <FQA/>
            <Footer/>
        </div>
    )
}