import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './WhyAgel.css';

export default function WhyAgel() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
        // console.log("width=", windowWidth)
    }, [])

    //box1 states
    const [box1TitleColor, setBox1TitleColor] = useState("why-agel-mini-box-title")
    const [box1SubTitleColor, setBox1SubTitleColor] = useState("why-agel-mini-box-subtitle")
    const [box1_img, setBox1_img] = useState("/images/trade-contract.svg")

    //box2 states
    const [box2TitleColor, setBox2TitleColor] = useState("why-agel-mini-box-title")
    const [box2SubTitleColor, setBox2SubTitleColor] = useState("why-agel-mini-box-subtitle")
    const [box2_img, setBox2_img] = useState("/images/increase-sales.svg")

    //box3 states
    const [box3TitleColor, setBox3TitleColor] = useState("why-agel-mini-box-title")
    const [box3SubTitleColor, setBox3SubTitleColor] = useState("why-agel-mini-box-subtitle")
    const [box3_img, setBox3_img] = useState("/images/personal-information.svg")

    //box4 states
    const [box4TitleColor, setBox4TitleColor] = useState("why-agel-mini-box-title")
    const [box4SubTitleColor, setBox4SubTitleColor] = useState("why-agel-mini-box-subtitle")
    const [box4_img, setBox4_img] = useState("/images/octagonal.svg")


    //box1 fns
    const setHoverColorsEnterBox1 = () => {
        if (windowWidth > 768) {
            setBox1TitleColor("why-agel-mini-box-title text-white text-underline");
            setBox1SubTitleColor("why-agel-mini-box-subtitle text-white");
            setBox1_img("/images/trade-contract-hover.svg")
        }
    }

    const setHoverColorsLeaveBox1 = () => {
        if (windowWidth > 768) {
            setBox1TitleColor("why-agel-mini-box-title");
            setBox1SubTitleColor("why-agel-mini-box-subtitle")
            setBox1_img("/images/trade-contract.svg")
        }
    }

    //box2 fns
    const setHoverColorsEnterBox2 = () => {
        if (windowWidth > 768) {
            setBox2TitleColor("why-agel-mini-box-title text-white text-underline");
            setBox2SubTitleColor("why-agel-mini-box-subtitle text-white");
            setBox2_img("/images/increase-sales-hover.svg")
        }
    }
    const setHoverColorsLeaveBox2 = () => {
        if (windowWidth > 768) {
            setBox2TitleColor("why-agel-mini-box-title");
            setBox2SubTitleColor("why-agel-mini-box-subtitle")
            setBox2_img("/images/increase-sales.svg")
        }
    }

    //box3 fns
    const setHoverColorsEnterBox3 = () => {
        if (windowWidth > 768) {
            setBox3TitleColor("why-agel-mini-box-title text-white text-underline");
            setBox3SubTitleColor("why-agel-mini-box-subtitle text-white");
            setBox3_img("/images/personal-information-hover.svg")
        }
    }

    const setHoverColorsLeaveBox3 = () => {
        if (windowWidth > 768) {
            setBox3TitleColor("why-agel-mini-box-title");
            setBox3SubTitleColor("why-agel-mini-box-subtitle")
            setBox3_img("/images/personal-information.svg")
        }
    }

    //box4 fns
    const setHoverColorsEnterBox4 = () => {
        if (windowWidth > 768) {
            setBox4TitleColor("why-agel-mini-box-title text-white text-underline");
            setBox4SubTitleColor("why-agel-mini-box-subtitle text-white");
            setBox4_img("/images/octagonal-hover.svg")
        }
    }

    const setHoverColorsLeaveBox4 = () => {
        if (windowWidth > 768) {
            setBox4TitleColor("why-agel-mini-box-title");
            setBox4SubTitleColor("why-agel-mini-box-subtitle")
            setBox4_img("/images/octagonal.svg")
        }
    }




    return (
        <div className='why-agel-container' id="whyAgel">

            {/* header */}
            <div className='text-center d-grid'>
                <span className='why-agel-title website-generic-font'>ليه تستخدم آجل</span>
                <span className='why-agel-subtitle website-generic-font mt-2'>آجل دايماً معاك , وعلشان آجل شريك التجار بنقدملك مميزات كتير</span>
            </div>

            {/* main part */}
            <Container className='mt-4 text-center d-flex justify-content-center align-items-center'>
                <Row>


                    {/* box1 */}

                    <Col xs="6" md="6" lg="6" xl="3" className='mt-2'>
                        <div className='why-agel-mini-container-box' onMouseOver={setHoverColorsEnterBox1} onMouseLeave={setHoverColorsLeaveBox1} onTouchStart={setHoverColorsLeaveBox1} onTouchEnd={setHoverColorsLeaveBox1} onTouchMove={setHoverColorsLeaveBox1} onClick={setHoverColorsLeaveBox1}>
                            <img src={box1_img} alt="trade-contract-img" className='img-fluid why-agel-mini-images mt-2' />
                            <div className='d-grid mt-3'>
                                <span className={`website-generic-font ${box1TitleColor}`}>تسهيل البيع</span>
                                <span className={`website-generic-font mt-2 mb-2 ${box1SubTitleColor}`}>
                                آجل بتوفرلك نظام  تقدر تشتري وتبيع بسهولة سواء كنت تاجر جملة أو تجزئة  من خلال تطبيق آجل .
                                </span>
                            </div>
                        </div>
                    </Col>


                    {/* box2 */}

                    <Col xs="6" md="6" lg="6" xl="3" className='mt-2'>
                        <div className='why-agel-mini-container-box' onMouseOver={setHoverColorsEnterBox2} onMouseLeave={setHoverColorsLeaveBox2}>
                            <img src={box2_img} alt="increase-sales-img" className='img-fluid why-agel-mini-images mt-2' />
                            <div className='d-grid mt-3'>
                                <span className={`website-generic-font ${box2TitleColor}`}>زيادة المبيعات</span>
                                <span className={`website-generic-font mt-2 mb-2 ${box2SubTitleColor}`}>
                                تاجر الجملة دلوقتي يقدر يتابع كل مبيعات فروعه من خلال حسابه الشخصي على تطبيق آجل .
                                </span>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" md="6" lg="6" xl="3" className='mt-2'>
                        <div className='why-agel-mini-container-box' onMouseOver={setHoverColorsEnterBox3} onMouseLeave={setHoverColorsLeaveBox3}>
                            <img src={box3_img} alt="personal-information-img" className='img-fluid why-agel-mini-images mt-2' />
                            <div className='d-grid mt-3'>
                                <span className={`website-generic-font ${box3TitleColor}`}>متابعة أعمالك</span>
                                <span className={`website-generic-font mt-2 mb-2 ${box3SubTitleColor}`}>
                                تاجر تجزئة  ومش معاك كاش  هتاخد بضاعتك بالأجل مع آجل . 
                                </span>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" md="6" lg="6" xl="3" className='mt-2'>
                        <div className='why-agel-mini-container-box' onMouseOver={setHoverColorsEnterBox4} onMouseLeave={setHoverColorsLeaveBox4}>
                            <img src={box4_img} alt="octagonal-img" className='img-fluid why-agel-mini-images mt-2' />
                            <div className='d-grid mt-3'>
                                <span className={`website-generic-font ${box4TitleColor}`}>المرابحة الإسلامية</span>
                                <span className={`website-generic-font mt-2 mb-2 ${box4SubTitleColor}`}>
                                التعامل بنظام المرابحة التجارية وفقا لأعراف السوق التجارية . (مرابحة بالأمر المباشر )
                                </span>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}