import React from 'react'
import { Container } from 'react-bootstrap'
import './Payment-methods-for-retailer-web-page.css'


export default function PaymentMethodsForRetailerWebPage() {
    return (

        <Container className='PaymentMethodsForRetailerWebPage'>

            {/* logo img */}
            <div className='mt-3'>
                <img src="./webPageAppsImages/PaymentMethodsForRetailerWebPageLogo.png" alt="Payment-methods-for-retailer-web-page-logo" className="Payment-methods-for-retailer-web-page-logo" />
            </div>

            {/* title */}
            <div className='text-center'>
                <p class="PaymentMethodsForRetailerWebPageTitle"> طرق السداد والمنافذ </p>
            </div>

            {/* content */}
            <p>
                عند قيامك بعملية الشراء يتم تقسيم قيمة الفاتورة على خطة الدفع التي اخترتها:
            </p>

            <div className="underline">
                <b> خطط الدفع:- </b>
            </div>

            <ul>
                <li>
                    1 شهر (2 دفعة)

                </li>
                <li style={{ marginTop: "-0.5rem", marginBottom: "-0.2rem" }}>
                    2 شهر (4 دفعة)
                </li>
                <li>
                    3 شهر (6 دفعة)
                </li>
            </ul>

            <p>
                ويتم احتساب نسبه 3% من قيمة الفاتورة على كل شهر: -
                <br />
                <b>
                    علي سبيل المثال؛
                </b>
                <br />
                لو كانت قيمة الفاتورة تساوي 10,000 جنيه واخترت خطه الدفع على 1 شهر ستكون اجمالي القيمة 10,300 جنيه مصري
                <br />
                اما إذا اخترت 2 شهر ستكون اجمالي القيمة 10,600 جنيه مصري
                <br />
                اما إذا اخترت 3 شهر ستكون اجمالي القيمة 10,900 جنيه مصري
                <br />
                تدفع الاقساط كل اسبوعين ومواعيدها ثابتة وهي يومي 1 و14 من كل شهر ميلادي.
                <br />
                ملحوظه؛ يتم احتساب مواعيد الأقساط من تاريخ عمليه الشراء يحسب القسط الأول بعد اسبوعين؛
                يجب ان يكون الفارق بين العملية وميعاد القسط على الاقل 10 ايام؛ بمعني إذا تمت العملية يوم 1 او 2 او 3 او 4 في
                الشهر،
                يكون القسط الاول يوم 14
                اما إذا تمت العملية بعد يوم 4 سيكون اول قسط يوم 1.
            </p>

            <div>
                <p className="underline">
                    <b>
                        ويتم دفع الاقساط من احدي الطرق التالية: -
                    </b>
                </p>
            </div>

            <div className="d-flex align-items-center">
                <img src="./webPageAppsImages/voda.png" alt="vodafone" className='vodafone-img' />
                <p className='vodafone-text'>
                    فودافون كاش: 01069890893
                </p>
            </div>

            <hr />

            <div className="d-flex align-items-center">
                <img src="/webPageAppsImages/fawry.png" alt="fawry" className="fawry-img" />
                <p className="fawry-text">
                    فوري:
                </p>
            </div>

            <ol>
                <li>
                    من صفحة الدفعات قم باختيار”تفاصيل الدفعة” من الدفعة المستحقة.
                </li>
                <li>
                    اختر “ادفع الآن” ثم اختر “ادفع عن طريق فوري”.
                </li>
                <li>
                    اضغط على “انسخ الرقم المرجعي” ثم اذهب لأقرب ماكينة فوري و أطلب منه الدفع باستخدام الكود.
                </li>
                <li>
                    في حالة نجاح العملية ستظهر أن الدفعة مدفوعة على التطبيق خلال 48 ساعة.
                </li>
            </ol>

            <hr />

            <div className="d-flex align-items-center">
                <img src="/webPageAppsImages/cib.png" alt="cib" className="cib-img" />
                <p className="cib-text" >
                    إيداع او تحويل بنكي:-
                </p>
            </div>

            <p>
                اسم الشركة: آجل لخدمات الدفع
                <br />
                رقم الحساب: 100050698313
                <br />
                فرع: جراند مول
                <br />
                IBAN: EG890010001800000100050698313
                <br />
                سوفت كودCIBEEGX01:
                <br />
            </p>

            <hr />

            <p>
                تحويل من محفظة بنكية: -
                <br />
                محفظة بنكية : 01067484817
            </p>

            <hr />

            <p>
                يكون هناك فترة سماح مدتها 24 ساعة؛ إذا كان ميعاد استحقاق القسط يوم 1 في الشهر فاَخر ميعاد للدفع بدون مصاريف
                إدارية يكون يوم 2 في الشهر، وإذا كان ميعاد دفع القسط يوم 14 في الشهر فاَخر ميعاد للدفع بدون مصاريف إدارية يكون
                يوم 15 في الشهر.
                في حاله التأخير يتم احتساب مصاريف إدارية لكل يوم تأخير؛
                <br />
                <b>طريقه احتساب المصاريف الإدارية: -</b>
                <br />
                5 جنيهات لكل 1000 جنيه من قيمة القسط في اليوم، على سبيل المثال لو كانت قيمة القسط 3000 جنيه ستكون المصاريف
                الإدارية 15 جنيه لكل يوم تأخير، فمثلا اذ كان التأخير لمده 3 ايام ستكون المصاريف الإدارية 45 جنيه و يتم دفع اصل
                القسط 3000 + 45 مصاريف إدارية.
            </p>

            <div className="text-center PaymentMethodsForRetailerWebPageFooter">
                <p>
                    <b>Land Line: </b> (+20225210348) (+20225210075)
                    <br />
                    <b>Website: </b> www.agel.io &nbsp;&nbsp;&nbsp; E-mail: cs@agel.io
                </p>
            </div>

        </Container >
    )
}