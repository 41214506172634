import React from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './CompaniesWorkingWithAgel.css'


export default function CompaniesLogosCover() {

    let navigate = useNavigate();


    const redirectToGoogleApps = () => {
        window.open('https://play.google.com/store/apps/details?id=com.agel', '_blank');
    }
    const redirectToIosApps = () => {
        window.open('https://apps.apple.com/eg/app/agel%D8%A7%D8%AC%D9%84/id1671890182', '_blank');
    }
    const redirectToLogosScreen = () => {
        navigate('/CompaniesLogos')
    }

    
    return (

        <div className='companiesLogosCoverWebPage text-center'>
            <div className='companies-logos-cover-grey-section'>
                <Container className='d-grid justify-content-center align-items-center text-center'>

                    {/* logo img */}
                    <div>
                        <img src="./webPageAppsImages/logo.png" alt="logo" className="mt-3 mb-3 Agel-logo-img-in-companies-logos-cover-page" />
                    </div>

                    {/* title */}
                    <p className="companiesLogosCoverWebPageTitle">بضاعتك بالآجل</p>

                    {/*apps stores image download  links*/}

                    <div className='d-flex justify-content-center align-items-center apps-download-link-container'>
                        <img src='/images/play-store-logo-download-app-screen.png' alt="google-store-app-img-download" target="_blank" className='img-fluid google-play-store-logo-download' onClick={redirectToGoogleApps} />
                        <img src='/images/ios-store-logo-download-app-screen.png' alt="apple-store-app-img-download" target="_blank" className='img-fluid apple-app-store-logo-download' onClick={redirectToIosApps} />
                    </div>

                </Container>
            </div>

            {/* redirect btn to logos screen  */}
            <div className='companies-logos-cover-white-section'>
                <Container className='d-flex justify-content-center align-items-center'>
                    <button className='redirect-btn-to-logos-page' onClick={redirectToLogosScreen}>
                        للتعرف على شركاء آجل
                    </button>
                </Container>
            </div>


        </div >
    )
}