import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HowToUseAgel.css'

export default function HowToUseAgel() {
    return (
        <div className="how-to-use-agel" id="howToUseAgel">

            {/* header */}
            <div className='text-center d-grid website-generic-font'>

                <span className='how-to-use-agel-title'>إزاى تستخدم آجل</span>

                <span className='how-to-use-agel-subtitle mt-3'>
                    <span className='mt-2'>
                        آجل شريك التجار هيوفرلك البضاعة اللي محتاجها لتجارتك بإجراءات بسيطة وسهلة .
                    </span>
                    <span className='mt-2'>
                        بسيط -سهل -مريح  التعامل  مع آجل مش  محتاج حساب بنكي ولا معاملات نقدية .
                    </span>
                    <span className='mt-2'>
                        دلوقتي بضاعتك مع آجل بتسهيلات في السداد , اشتري بضاعتك وسدد على دفعات .
                    </span>
                </span>

            </div>

            {/* how to use agel image */}
            <div className='text-center'>
                <img src="/images/how-to-use-agel.png" alt="how to use agel img" className='img-fluid how-to-use-agel-img' />
            </div>

            {/* how to use agel mini cards */}
            <Container className='how-to-use-agel-container'>

                <Row>

                    <Col xs="12" md="12" lg="4" className='d-flex justify-content-center align-items-center mb-3'>
                        {/* first card */}
                        <div className='text-center d-grid how-to-use-agel-card'>
                            <span className='how-to-use-agel-card-title mt-2 website-generic-font'>
                                الخطوة الأولى
                            </span>
                            <span className='how-to-use-agel-card-subtitle website-generic-font'>
                                حدد نوع البضاعة اللى محتاجها و أختار التاجر اللى يناسبك و تتواصل معاه و اشترى بضاعتك منه حسب الرصيد المتاح لك في آجل
                            </span>
                        </div>

                    </Col>

                    <Col xs="12" md="12" lg="4" className='d-flex justify-content-center align-items-center mb-3'>
                        {/* second card */}
                        <div className='text-center d-grid how-to-use-agel-card how-to-use-agel-middle-card'>
                            <span className='how-to-use-agel-card-title mt-2 website-generic-font'>
                                الخطوة الثانية
                            </span>
                            <span className='how-to-use-agel-card-subtitle website-generic-font'>
                                هتعرف التاجر إن وسيلة الدفع هي آجل و تختار خطة الدفع المناسبة ولو عليك دفعه قريبة هتشوفها قبل ما توافق على الفاتورة الحالية
                            </span>
                        </div>
                    </Col>

                    <Col xs="12" md="12" lg="4" className='d-flex justify-content-center align-items-center mb-3'>
                        {/* third card */}
                        <div className='text-center d-grid how-to-use-agel-card'>
                            <span className='how-to-use-agel-card-title mt-2 website-generic-font'>
                                الخطوة الثالثة
                            </span>
                            <span className='how-to-use-agel-card-subtitle website-generic-font'>
                                هتأكد عملية البيع بعد ماتكون عرفت دفعتك القادمة من الفاتورة و مواعيد السداد
                            </span>
                        </div>
                    </Col>

                </Row>



            </Container>

        </div>
    )
}