import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { MdOutlineDelete, MdCancel } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";
import './Delete-user-account-web-page.css'



export default function DeleteUserAccountWebPage() {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [showDeleteBtn, setShowDeleteBtn] = useState(true);
    const [showConfirmationMsgContainer, setShowConfirmationMsgContainer] = useState(false);
    const [showFinalConfirmationMsgForDeletion, setShowFinalConfirmationMsgForDeletion] = useState(false);

    const deleteAccount = (e) => {
        e.preventDefault();
        setShowDeleteBtn(false);
        setShowConfirmationMsgContainer(true);
    }

    const cancelDeleteAccount = () => {
        setShowConfirmationMsgContainer(false);
        setShowDeleteBtn(true);
    }

    const confirmDeleteAccount = () => {
        setShowConfirmationMsgContainer(false);
        setShowFinalConfirmationMsgForDeletion(true);
        setPhoneNumber('');
        setCode('');
    }

    return (
        <Container className='DeleteUserAccountWebPage'>

            {/* title */}
            <div className='text-center mt-4 mb-3'>
                <p className="DeleteUserAccountWebPageTitle">
                    مسح حساب العميل
                </p>
            </div>

            {/* content */}

            {/* form */}
            <Container className='d-grid justify-content-center align-items-center mt-5'>
                <Form onSubmit={deleteAccount}>
                    <Form.Group className='mb-3'>
                        <Form.Label>رقم التليفون</Form.Label>
                        <Form.Control type="text" className='text-muted' value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value); }} required />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>الكود السري</Form.Label>
                        <Form.Control type="text" className='text-muted' value={code} onChange={(e) => { setCode(e.target.value); }} required />
                    </Form.Group>

                    {showDeleteBtn &&
                        <div className='d-grid'>
                            <button type='submit' className='DeleteUserAccountWebPageDeleteBtn'>
                                <MdOutlineDelete className='recycle-bin-icon' />
                                مسح الحساب
                            </button>
                        </div>
                    }

                    {showConfirmationMsgContainer &&
                        <div className='DeleteUserAccountWebPageConfirmationMsgContainer'>
                            <span>
                                هل متأكد من رغبتك في مسح حسابك
                            </span>
                            <Row className='d-flex justify-content-around align-items-center mt-3'>
                                <Col xs="6">
                                    <button type='button' className='DeleteUserAccountWebPageConfirmDeletionBtn' onClick={confirmDeleteAccount}>
                                        <GiConfirmed className='confirm-icon' />
                                        تأكيد
                                    </button>
                                </Col>
                                <Col xs="6">
                                    <button type='button' className='DeleteUserAccountWebPageCancelDeletionBtn' onClick={cancelDeleteAccount}>
                                        <MdCancel className='cancel-icon' />
                                        الغاء
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    }

                    {showFinalConfirmationMsgForDeletion &&
                        <div className='DeleteUserAccountWebPageFinalConfirmationMsg'>
                            <GiConfirmed className='confirm-icon' />
                            <span>
                                تم مسح الحساب احد مندوبي خدمة العملاء سيقوم بالتواصل معكم
                            </span>
                        </div>
                    }

                </Form>
            </Container>
        </Container>
    )
}