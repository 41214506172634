import React from 'react'
import { Container } from 'react-bootstrap'
import './About-agel-web-Page.css'



export default function AboutAgelWebPage() {
    return (

        <Container className='aboutAgelWebPage'>

            {/* logo img */}
            <div className='mt-3'>
                <img src="./webPageAppsImages/logo.png" alt="logo" className="about-Agel-Web-Page-logo" />
            </div>

            {/* title */}
            <div className='text-center mt-4 mb-3'>
                <p className="aboutAgelWebPageTitle"> عن شركة آجل و خدماتها</p>
            </div>

            {/* content */}
            <ul>
                <li>أجــل شريك التـجار الموثوق</li>
                <p> - هنوفر الحل للمعادلة الصعبة بين توفير البضاعة بالأجل للمشاريع الصغيرة لتجار التجزئة في نفس اليوم ودفع اموال
                    تجار الجملة بدون أجــل خلال 24 ساعة فقط.</p>
                <p> - هنساعدك توصل لهـدفـــك.. واكتــر كمــان</p>
                <p> -  التعامل مع أجـل بسيط ومريح لأنك مش هتحتاج حساب بنكي ولا معاملات معقدة.</p>
                <p> -  اشتري بضاعتك ومتشلش هم الكاش وسدد على دفعات خلال 90 يوم.</p>
                <hr />
                <li>تم تأسيس شركة أجل على ايد خبراء في مجال تطوير الاعمال والتمويل والتكنولوجيا والتصنيع لأكثر من 50 سنة من
                    الخبرة.</li>

                <li>بنوفر لتجار الجملة والتجزئة حلول لتوفير سيولة لتجارتهم، وبكدا هيستفيد تاجر الجملة من دورة مالية أسرع من المعتاد،
                    وهيستفيد تاجر التجزئة من سداد بدون معاملات معقدة.</li>

                <li>بدأنا بإسكندرية، وهدفنا نجمع كل تجار مصر في تطبيق واحد ونقدم لهم المنتجات المناسبة لاحتياجات تجارتهم .</li>
                <hr />
                <li>  أجــل دايما معــاك، وتقدم لك مميزات كتير زي:</li>

                <ol>
                    <li>تعامل بنظام المرابحة التجارية المتوافق مع احكام الشريعة الاسلامية.</li>

                    <li>تشتري وتبيع بسهولة من خلال جهاز اجل لتاجر الجملة ومن خلال تطبيق اجل لتاجر التجزئة.</li>

                    <li>لو انت تاجر جملة تقدر تزود مبيعاتك ولو انت تاجر تجزئة تقدر تأخذ بضاعتك بالأجل.</li>

                    <li>بيقدر تاجر الجملة يتابع كل مبيعات فروعـه من خلال حسابه الشخصي على تطبيق أجــل. </li>
                </ol>
            </ul>

        </Container>
    )
}