import React from 'react'
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


//components

// website components
import Layout from './screens/Layout';

// apps web pages components
import AboutAgelWebPage from './mobile_web_pages/About-agel-web-Page/About-agel-web-Page';
import TermsAndConditionsWebPage from './mobile_web_pages/Terms-and-conditions-web-page/Terms-and-conditions-web-page';
import PaymentMethodsForRetailerWebPage from './mobile_web_pages/Payment-methods-for-retailer-web-page/Payment-methods-for-retailer-web-page';
import Zammit from './mobile_web_pages/Zammit/Zammit';
import CompaniesLogosCover from './mobile_web_pages/CompaniesWorkingWithAgel/CompaniesLogosCover';
import CompaniesLogos from './mobile_web_pages/CompaniesWorkingWithAgel/CompaniesLogos';
import DeleteUserAccountWebPage from './mobile_web_pages/Delete-user-account-web-page/Delete-user-account-web-page';


function App() {
  return (
    <Routes>

      {/* agel website routes*/}
      <Route path="/" element={<Layout />} />

      {/* apps web pages routes */}
      <Route path="/about-agel" element={<AboutAgelWebPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsWebPage />} />
      <Route path="/payment-methods" element={<PaymentMethodsForRetailerWebPage />} />
      <Route path="/zammit" element={<Zammit />} />
      <Route path="/CompaniesLogosHome" element={<CompaniesLogosCover />} />
      <Route path="/CompaniesLogos" element={<CompaniesLogos />} />
      <Route path="/delete-user-account" element={<DeleteUserAccountWebPage />} />


    </Routes>
  );
}

export default App;
