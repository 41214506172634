import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './footer.css'

export default function Footer() {

    function redirectToFacebook() {
        window.open("https://www.facebook.com/agelegypt")
    }

    function redirectToLinkedin() {
        window.open("https://www.linkedin.com/company/agelegypt/")
    }

    
    return (
        <div className="agel-footer website-generic-font">
            {/* upper footer part */}

            <div className='agel-footer-upper-part'>

                <Row style={{ padding: "2rem" }}>

                    {/* agel-footer-upper-part-img */}
                    <Col xs="12" md="12" lg="6" className="agel-footer-upper-part-img-container">
                        <img src='/images/footer-logo.png' alt="agel-footer-upper-part-img" className='img-fluid agel-footer-upper-part-img' />
                    </Col>

                    {/* agel-footer-upper-part-info */}
                    <Col xs="12" md="12" lg="6" className='d-flex footer-info-container'>


                        {/* part1 */}
                        <div className='d-grid agel-footer-upper-part-info-part1'>
                            <span className='agel-footer-upper-part-info-part1-span1 mb-2'>
                                <span className='agel-footer-upper-part-info-part1-span1-underlined'>
                                    البريد
                                </span>
                                الإلكتروني
                            </span>

                            <span className='agel-footer-upper-part-info-part1-span2 mb-3'>
                                info@agel.io
                            </span>

                            <span className='agel-footer-upper-part-info-part1-span3 mb-2 d-flex'>
                                <span className='agel-footer-upper-part-info-part1-span3-underlined'>
                                    رقم
                                </span>
                                التليفون
                            </span>

                            <span className='agel-footer-upper-part-info-part1-span4 mb-3' dir='ltr' style={{ textAlign: 'right' }}>
                                (+202 -25210348)
                                <br />
                                (+202 -25210075)
                                <br />
                                (+202 -2521019)
                            </span>

                            <div className='footer-socials-part1 mt-2'>
                                <img src="/images/fb-icon.svg" alt="fb-icon" className='img-fluid footer-fb-icon' onClick={redirectToFacebook}/>
                                <img src="/images/linkedin-icon.svg" alt="linkedin-icon" className='img-fluid footer-linkedin-icon' onClick={redirectToLinkedin}/>
                            </div>

                        </div>


                        {/* vertical border */}
                        <div className="vr agel-footer-upper-part-info-border"></div>


                        {/* part2 */}
                        <div className='d-grid agel-footer-upper-part-info-part2'>
                            <span className='agel-footer-upper-part-info-part2-span1 mb-2'>
                                <span className='agel-footer-upper-part-info-part2-span1-underlined'>
                                    الفروع
                                </span>
                            </span>

                            <span className='agel-footer-upper-part-info-part2-span2 mb-3'>
                                فرع القاهرة:
                                <br />
                               13 - شارع 254 دجلة المعادى
                            </span>

                            <span className='agel-footer-upper-part-info-part2-span3'>
                                فرع الأسكندرية:
                                <br />
                                27 - شارع رشدى باشا - الدور الأول - الأسكندرية 
                            </span>

                            <div className='mt-2 footer-socials-part2'>
                                <img src="/images/fb-icon.svg" alt="fb-icon" className='img-fluid footer-fb-icon' onClick={redirectToFacebook}/>
                                <img src="/images/linkedin-icon.svg" alt="linkedin-icon" className='img-fluid footer-linkedin-icon' onClick={redirectToLinkedin}/>
                            </div>

                        </div>

                    </Col>

                </Row>
            </div>

            {/* lower footer part */}
            <div className='agel-footer-lower-part'>
                <Container className='text-center'>
                    Ⓒ جميع الحقوق محفوظه لشركه آجل لخدمات الدفع الإلكترونى
                </Container>
            </div>

        </div>
    )
}