import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './CompaniesWorkingWithAgel.css'


export default function CompaniesLogos() {


    const firstLogosContainer = [2, 3, 5, 6, 7, 11, 12, 13, 14, 15, 17, 19, 20, 22, 24, 25, 26, 28, 29, 30, 31, 32, 33, 34]
    const secondLogosContainer = [35, 38, 40, 41, 42, 44, 45, 46, 48, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 63, 67, 68]
    const thirdLogosContainer = [69, 70, 71, 74, 75, 76, 77, 79, 80, 81, 84, 85, 86, 88, 89, 90, 94, 95, 96, 98, 99, 101, 102, 103]
    const fourthLogosContainer = [105, 106, 108, 109, 110, 111, 118, 119, 120, 121, 123, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 140]
    const fifthLogosContainer = [8, 97, 100, 104, 113, 114, 115, 47, 39, 141, 92, 112, 136, 139]

    
    return (
        <div className='d-flex justify-content-center align-items-center text-center'>
            <div className='companiesLogos-images-container'>

                {/* firstLogosContainer.jpg */}
                <Row className='d-flex justify-content-center align-items-center text-center mt-5'>
                    {firstLogosContainer.map((i) =>
                        <Col xs="3" lg="2" className='mb-2 companiesLogos-images-col'>
                            <img src={`/webPageAppsImages/companiesLogos/${i}.jpg`} alt={`img-${i}`} className='img-fluid companies-logos-frame' />
                        </Col>
                    )}
                </Row>




                {/* secondLogosContainer.jpg */}
                <Row className='d-flex justify-content-center align-items-center text-center mt-5'>
                    {secondLogosContainer.map((i) =>
                        <Col xs="3" lg="2" className='mb-2 companiesLogos-images-col'>
                            <img src={`/webPageAppsImages/companiesLogos/${i}.jpg`} alt={`img-${i}`} className='img-fluid companies-logos-frame' />
                        </Col>
                    )}
                </Row>




                {/* thirdLogosContainer.jpg */}
                <Row className='d-flex justify-content-center align-items-center text-center mt-5'>
                    {thirdLogosContainer.map((i) =>
                        <Col xs="3" lg="2" className='mb-2 companiesLogos-images-col'>
                            <img src={`/webPageAppsImages/companiesLogos/${i}.jpg`} alt={`img-${i}`} className='img-fluid companies-logos-frame' />
                        </Col>
                    )}
                </Row>




                {/* fourthLogosContainer.jpg*/}
                <Row className='d-flex justify-content-center align-items-center text-center mt-5'>
                    {fourthLogosContainer.map((i) =>
                        <Col xs="3" lg="2" className='mb-2 companiesLogos-images-col'>
                            <img src={`/webPageAppsImages/companiesLogos/${i}.jpg`} alt={`img-${i}`} className='img-fluid companies-logos-frame' />
                        </Col>
                    )}
                </Row>




                {/* fifthLogosContainer.jpg*/}
                <Row className='d-flex justify-content-center align-items-center text-center mt-5'>
                    {fifthLogosContainer.map((i) =>
                        <Col xs="3" lg="2" className='mb-2 companiesLogos-images-col'>
                            <img src={`/webPageAppsImages/companiesLogos/${i}.jpg`} alt={`img-${i}`} className='img-fluid companies-logos-frame' />
                        </Col>
                    )}
                </Row>

            </div>
        </div>
    )
}