import React, { useState, useEffect } from 'react'
import { Container, Modal } from 'react-bootstrap'
import * as Scroll from 'react-scroll';

import './homeScreen.css'

export default function HomeScreen() {

    //scroll arrows
    let scroll = Scroll.animateScroll;


    const [downloadAppImg, setDownloadAppImg] = useState("/images/downloadAppHomeScreen.svg")
    const [show, setShow] = useState(false);
    const [scrollableArrow, setScrollableArrow] = useState('/images/downArrow.svg')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)



    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
        }
        )

    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => {

            if (window.scrollY > 100) {  ///el mafrod 400 bs lm a nzbot el height bta3 el home
                setScrollableArrow('/images/upArrow.svg');
            } else {
                setScrollableArrow('/images/downArrow.svg');
            }
        })
    }, [])

    const setHoverEnterDownloadAppImg = () => {
        if (windowWidth > 768) {
            setDownloadAppImg("/images/downloadAppHomeScreenHover.svg");
        }
    }

    const setHoverLeaveDownloadAppImg = () => {
        if (windowWidth > 768) {
            setDownloadAppImg("/images/downloadAppHomeScreen.svg");
        }
    }
    function redirectToGoogleApps() {
        window.open("https://play.google.com/store/apps/details?id=com.agel")
    }


    function redirecttArrow() {
        if (scrollableArrow === "/images/downArrow.svg") {
            scroll.scrollToBottom();
            // console.log("down")
            // window.scrollTo(0,0)
        }
        else {
            scroll.scrollToTop();
            // console.log("up")
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (

        <div className='homeScreen-container website-generic-font text-white' id="homeScreen">
            {/* text content*/}
            <div className='d-grid'>
                <span className='homeScreen-phrase1'> {/* homeScreen-phrase1 */}
                    آجــل شريك التـجار الموثوق
                </span>
                <span className='homeScreen-phrase2'> {/*homeScreen-phrase2 */}
                    هنوفر الحل للمعادلة الصعبة بين توفير البضاعة بالآجل للمشاريع الصغيرة لتجار التجزئة في نفس اليوم ودفع أموال تجار الجملة بدون آجــل خلال 24 ساعة فقط
                </span>
                <span className='homeScreen-phrase3'> {/* homeScreen-phrase3 */}
                    هنساعدك توصل لهـدفـــك .. وأكتــر كمــان
                </span>
            </div>


            {/* clicked target */}
            <div className='d-flex justify-content-center align-items-center clicked-icons-conainer'>

                <img src={downloadAppImg} alt="downloadAppHomeScreen" className='img-fluid downloadAppImg' onMouseOver={setHoverEnterDownloadAppImg} onMouseLeave={setHoverLeaveDownloadAppImg} onClick={redirectToGoogleApps} />
                <div>
                    <img src='/images/homeScreenVideo.png' alt="homeScreenVideo" className='img-fluid videoImg' onClick={handleShow} />
                    <span className='video-text'>
                        شاهد الفيديو
                    </span>
                </div>
            </div>


            {/* scroll fixed img */}
            <Container fluid className='d-flex justify-content-end align-items-end' style={{ position: 'relative' }}>
                <img src={scrollableArrow} alt="homeScreenVideo" className='scrollable-fixed-arrow' onClick={redirecttArrow} />
            </Container>

            <Modal show={show} onHide={handleClose} dir="ltr" centered>
                <Modal.Body className='text-center video-container'>
                    {/* <video id="MY_VIDEO_1" className="video-js vjs-default-skin video" controls
                        preload="auto" onClick={(e) => e.play()}>
                        <source src="/websiteVideo/Welcome to Agel.webm" id="videojsplay" type='video/mp3' />
                        <source src="/websiteVideo/Welcome to Agel.webm" id="videojsplay" type='video/webm' />
                    </video> */}

                    <iframe id="ytplayer" type="text/html" title="agel-video"
                        src="https://www.youtube.com/embed/d_F5Q8SDAAY"
                        allowFullScreen="true" 
                       style={{width:"100%",height:"100%"}}
                        frameborder="0"></iframe>
                </Modal.Body>
            </Modal>


        </div>


    )
}


