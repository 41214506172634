import React from 'react'
import { Container } from 'react-bootstrap'
import './Terms-and-conditions-web-page.css'



export default function TermsAndConditionsWebPage() {
    return (
        <Container className='termsAndConditionsWebPage'>


            {/* logo img */}
            <div className='mt-3'>
                <img src="./webPageAppsImages/logo.png" alt="logo" className="terms-and-condition-web-Page-logo" />
            </div>

            {/* title */}
            <div className='text-center mt-4 mb-3'>
                <p className="termsAndConditionsWebPageTitle"> آجل لخدمات الدفع الإلكترونى</p>
                <p class="termsAndConditionsWebPageSubtitle"> الشروط و الأحكام </p>
            </div>

            {/* content */}
            <ul>
                <li> معلومات و بيانات الاتصال: </li>

                <p>
                    نحن شركة آجـل لخدمات الدفع الإلكتروني ("الشركـة") ، شركة مساهمة مصرية تأستت وفقاً
                    لقوانين جمهورية مصر العربية ، تحمل بطاقة ضريبية رقم (652 - 964 -052 )
                    ومسجلة بسجل تجاري استثمار القاهرة <br />تحت رقم (174334 )وعنوانها
                    ( ٧ شارع الجولف المعادى القاهرة)المسجل.
                    <br />
                    تعمل الشركة في مجال التجارة والتكنولوجيا المالية، وتقدم خدمتها من خلال تطبيقاً رقمياً يسهم في رفع كفاءة
                    وزيادة حجم التجارة
                    في الأسواق المحلية من خلال تحفيز حجم مبيعات التجار، توفير قنوات سداد<br /> و تحصيل الكترونية لمقابل السلع كذلك
                    اتاحة
                    اليات مبتكرة للدفع بالأجل مقابل السلع والمنتجات المباعة باعتماد أنظمة المرابحة والمشاركة المتوافقة مع احكام
                    الشريعة
                    الإسلامية. تتواجد الشركة حاليا في <br />محافظتي القاهرة الكبرى والإسكندرية وتهدف للتوسع في محافظات أخري.

                    يمكنكم التواصل معنا مباشرة عن طريق ممثلي خدمة العملاء (0225210075/0225210348) أو عبر البريد الإكتروني التالى
                    : (cs@agel.io).

                    في حالة رغبتنا في التواصل معكم سنقوم بذلك عبر الهاتف، أو البريد الإلكتروني، أو من خلال العنوان البريدي
                    المسجل لدينا
                    في قاعدة بيانات تطبيق الهاتف المحمول أو موقعنا الإلكتروني.
                </p>

                <li> العلاقة التعاقدية</li>

                <p>
                    هذه الشروط والأحكام ("الشروط") تحكم وصول و/أو استخدام الأشخاص الطبيعية والأشخاص الاعتبارية في أي منطقة في
                    الجمهورية
                    مصر العربية للتطبيقات والمواقع والمحتوى والمنتجات والخدمات ("الخدمات") التي تقدمها الشركة.
                    يرجى االطالع على الشروط التالية وقراءتها بعناية وقبولها دون قيد أو شرط عبر الضغط على زر "موافقة".
                    يشكل وصولك إلى الخدمات واستخدامك لها، موافقه على الالتزام بهذه الشروط، مما ينشئ علاقة تعاقدية بينك وبين
                    الشركة. إذا
                    كنت لا توافق على هذه الشروط، فلا يجوز لك الحصول على الخدمات أو استخدامها. تحكم هذه الشروط على وجه التحديد
                    العلاقة بين الشركة والمستخدمين الذين ليسوا ملزمين بـ الشركة بأي ترتيب تجاري سابق؛ علاوة على ذلك. يجوز للشركة
                    إنهاء
                    أي من هذه الشروط أو أي من الخدمات المتعلقة بك على الفور، أو قد توقف أو ترفض الوصول إلى الخدمات أو أي جزء
                    منها
                    في أي وقت ولأي سبب من الأسباب. يعرف مصطلح "الكتابة " بأنه
                    محتوى رسائل البريد الإلكتروني و/اوالاشعارات المرسلة للمستخدم من خلال التطبيق الخاص
                    بالشركة.

                    إن استخدامك لتطبيق الهاتف أو البوابة الإكترونية، هو إقرار منك على فهمك لهذه الشروط. وفي حال عدم الموافقة على
                    هذه
                    الشروط، يتوجب عليك عدم استخدام أي من تطبيق الهاتف المحمول أو البوابة الإلكترونية. بالضغط علي "موافقة"، فأنت
                    تؤكد
                    قبول هذه الشروط والالتزام بالإشعارات القانونية، وسياسة الخصوصية، وشروط الإخلاء من المسئولية، والبنود والشروط
                    الإضافية
                    التي تظهر أعلى تطبيق الهاتف و/أو البوابة الإلكترونية. يرجي الاطلاع على هذه الشروط وقراءتها بعناية. لا تتحمل
                    الشركة
                    أي مسئولية عن المنتجات و/أو الخدمات المقدمة عبر تطبيقات الهاتف أو البوابة الالكترونية. ويعتبر الضغط على زر
                    "موافقة"
                    أسفل هذه الصفحة، هو إقرار منك بقبول الشروط والأحكام
                    تحتفظ شركة آجل بكامل الحقوق ، وفقاً لتقديراتها، في تغيير أو تعديل أو إضافة أو إزالة أي من الشروط والأحكام في
                    أي وقت
                    دون إخطار مسبق. تسري هذه التغييرات فور نشرها أو الكشف عنها عبر تطبيق الهاتف أو البوابة االإلكترونية. يرجى
                    مراجعة
                    هذه الشروط بشكل منتظم للحصول على كافة التحديثات. عند إجراء أية تعديلات على هذه الشروط، فإن الاستمرار في
                    استخدام
                    تطبيق الهاتف و/أو البوابة الإلكترونية يشكل موافقة منك على هذه التعديلات
                    قد تنطبق بعض الشروط التكميلية على خدمات معينة، مثل السياسات الخاصة بحدث أو نشاط أو ترويج معين، وسيتم إبلاغك
                    بهذه
                    الشروط التكميلية المتعلقة بالحدث أو النشاط أو الترويج المذكور. تتم إضافة الشروط التكميلية إلى ("الشروط")
                    لأغراض الخدمات المقدمة ، و تعتبر جزءاً منها. تسود الشروط التكميلية على هذه ("الشروط") في حالة وجود تعارض
                    فيما يتعلق بالخدمات المقدمة.
                    نقوم بإدارة بياناتك الشخصية فيما يتعلق بالخدمات على النحو المنصوص عليه في سياسة خصوصية آجل، والتي يمكن
                    الوصول إليها من خلال موقع الويب
                    (www.agel.com.eg).
                    قد توفر شركة آجل الوصول الضروري إلى معلوماتك لغرض وحيد هو
                    الاستفادة من الخدمات التي طلبتها من واحد أو أكثر من مقدمي الخدمات الذين يمكن الوصول إليهم من خلال التطبيق
                    الخاص
                    بالشركة.
                    يتم توفير المواد المتاحة من خلال الخدمات "كما هي"، دون ضمان من أي نوع، سواء أكان صريحاً او ضمنياً
                    ، بما في ذلك، على سبيل المثال لا الحصر
                    ضمانات القابلية للتسويق أو الملاءمة لغرض معين أو عدم الانتهاك. على وجه الخصوص، لا يتم تقديم
                    أي ضمانات أو بيانات فيما يتعلق بدقة أو اكتمال المواد المذكورة. ستقوم الشركة بشكل دوري، دون إشعار مسبق،
                    بإضافة أو تغيير
                    أو تحسين أو استكمال المواد المعروضة في الخدمات. لن تكون الشركة بأي حال من الأحوال مسؤولة عن أي خسارة أو ضرر
                    أو
                    مسؤولية أو مصاريف يزعم انها حدثت أو تكبدها استخدام الخدمات، بما في ذلك، على سبيل المثال لا الحصر، أي أخطاء
                    أو سهو
                    أو تأخير في تقديم الخدمة أو انقطاعها. يتحمل جميع المستخدمين ("المستخدم"، "أنت") المسؤولية الكاملة عن استخدام
                    الخدمات.
                    بأي حال من الأحوال، بما في ذلك، على سبيل المثال لا الحصر، الإهمال، ولن تكون الشركة مسؤولة عن أي أضرار مباشرة
                    أو
                    غير مباشرة أو عرضية أو خاصة أو تبعية، حتى في الحالات التي تم فيها إبالغ الشركة بإمكانية حدوث مثل هذه
                    الأضرار. يقر
                    المستخدم ويوافق بشكل خاص على أن الشركة ليست مسؤولة عن أي سلوك لأي مستخدم للتطبيق.
                </p>

                <li>الخدمـات </li>

                <p>
                    توفر آجل ("الشركة") للمستخدمين تطبيقاً رقمياً تفاعلياً ("التطبيق ")
                    يعمل على خدمة فئتين من التجار وهما،
                </p>

                <b> (أ) تجار التجزئه</b>

                <b>
                    يتيح التطبيق الخاص بشركة اجل لتجار التجزئة الخدمات الاتية:
                </b>

                <p>
                    <b> أولاً : </b>

                    التعرف، التواصل، رصد ومتابعة تجار الجملة، المصانع والمستوردين العاملين داخل جمهورية مصر العربية و الذين
                    يوفرون
                    البضائع التي يتعامل بها في تجارته، كما يقوم التطبيق بإرسال اشعار لتاجر التجزئة في حال قيام أحد تجار الجملة
                    بإدخال منتجات
                    جديدة او تخفيض على المنتجات التي لديه.
                </p>

                <p>
                    <b> ثانياً : </b>

                    يمكن للتاجر الحصول على حد من البضائع بنظام المرابحة التجارية بحيث تقوم شركة آجل بشراء البضائع التي يحتجها
                    لاستكمال دورته التجارية ثم تقوم ببيعها له بالقسط في مقابل هامش ربح ثابت. حال رغبة التاجر الاستفادة من هذه
                    الخدمة يقوم بمليء
                    كافة البيانات المطلوبة عن طريق التطبيق، ثم يقوم احد مندوبي الشركة بزيارته ميدانية للتأكد من صحة البيانات
                    والمستندات ويطلب
                    منه التوقيع علي العقد الخاص بالشركة ثم يقوم التطبيق باستخدام خاصية الذكاء الاصطناعي باحتساب الحد الائتماني
                    الذي في وسع
                    التاجر تحمله ويتم تفعيل هذا الحد المحتسب للتاجر والذي يمكنه من التوجه لأي من تجار الجملة المشتركين في الشبكة
                    واختيار
                    بضائع و/او سلع في يعادل ثمنها الحد الائتماني المقرر له ثم تقوم الشركة بشراء هذه البضائع وإعادة بيعها له
                    مقابل هامش ربح ثابت
                    علي ان يتسلم التاجر البضائع مباشرة من تاجر الجملة ثم يقوم بسداد ثمن البضائع للشركة علي أقساط بعد أن يقوم
                    ببيع هذه البضائع
                    لعملائه. يمكن للتاجر التجزئة اختيار عدد الدفعات التي يرغب في السداد عليها على الا تتجاوز ست دفعات، ومدة
                    الدفعة أربعة عشر
                    يوما. لا يتحمل التاجر أي مصاريف لفتح الحساب كما يمكنه في حالة تعسره إعادة البضائع التي لم يتمكن من سداد
                    ثمنها عن طريق
                    أن تقوم الشركة بإعادة شرائها منه واسقاط ما عليه من دفعات مستحقة.
                </p>

                <p>
                    <b> ثالثاً : </b>

                    تتعاون الشركة مع بنك مرخص داخل جمهورية مصر العربية لإصدار بطاقة بنكية مسبقة الدفع تحمل شعار مزدوج هو شعار
                    الشركة والبنكي، علي أن يمنح كل تاجر مشترك في خدمات الشركة بطاقة تمكنه من سداد الدفعات المستحقة للشركة من
                    خلال أي
                    من مكن الصراف الالي او نقاط الدفع الالكترونية كما ستوفر البطاقة أيضا إمكانية الاستخدام في سداد كافة
                    المدفوعات سواء الحكومية
                    وغير الحكومية بما فيها سداد الفواتير او إتمام عمليات الشراء بشكل الكتروني.

                </p>

                <p>
                    <b> رابعاً : </b>

                    له الاستفادة من عدد من أدوات تنظيم و إدارة الاعمال الرقمية التي تتيح له إدارة وتسجيل مبيعاته، ومتابعة حسابات
                    عملائه غير
                    مكتمل سدادها، مع إدارة تدفقاته النقدية، كذلك يتيح التطبيق عدد من الدراسات والمقالات التوعوية والتعليمية
                    لتحسين الوعي المالي
                    ورفع الثقافة المالية والتجارية.
                </p>


                <b> (ب) تجار الجملة: </b>

                <b>
                    يتيح التطبيق الخاص بشركة آجل لتجار الجملة الخدمات الاتية:
                </b>

                <p>
                    <b> أولاً : </b>

                    التعرف، التواصل، رصد ومتابعة المصانع والمستوردين العاملين داخل جمهورية مصر العربية والذين يوفرون البضائع
                    التي يتعامل بها في تجارته، كما يقوم التطبيق بإرسال اشعار للتاجر في حال قيام أحد المصانع بإدخال منتجات جديدة
                    او تخفيض على المنتجات التي لديه.
                </p>

                <p>
                    <b> ثانياً : </b>

                    يمكن للتاجر الحصول على حد من البضائع بنظام الم اربحة التجارية بحيث تقوم شركة آجل بش ارء البضائع التي يحتجها
                    لاستكمال دورته التجارية ثم تقوم ببيعها له بالقسط في مقابل هامش ربح ثابت. حال رغبة التاجر الاستفادة من هذه
                    الخدمة يقوم
                    بمليء كافة البيانات المطلوبة عن طريق التطبيق، ثم يقوم احد مندوبي الشركة بزيارته ميدانية للتأكد من صحة
                    البيانات والمستندات ويطلب منه التوقيع علي العقد الخاص بالشركة ثم يقوم التطبيق باستخدام خاصية الذكاء
                    الاصطناعي باحتساب الحد الائتماني الذي في وسع التاجر تحمله ويتم تفعيل هذا الحد المحتسب للتاجر والذي يمكنه من
                    التوجه لأي من المصانع المشتركين في الشبكةواختيار بضائع و/او سلع في يعادل ثمنها الحد الائتماني المقرر له ثم
                    تقوم الشركة بش ارء هذه البضائع وإعادة بيعها له مقابل هامش ربح صغير علي ان يتسلم التاجر البضائع مباشرة من
                    المصنع ثم يقوم بسداد ثمن البضائع للشركة علي أقساط بعد أن يقوم ببيع هذه البضائع لعملائه. يمكن للتاجر اختيار
                    عدد الدفعات التي يرغب في السداد عليها على الا تتجاوز ست دفعات، ومدة الدفعةأربعة عشر يوما .
                </p>

                <p>
                    <b> ثالثاً : </b>

                    تتعاون الشركة مع بنك مرخص داخل جمهورية مصر العربية لإصدار بطاقة بنكية مسبقة الدفع تحمل شعار مزدوج هو شعار
                    الشركة والبنكي، علي أن يمنح كل تاجر مشترك في خدمات الشركة بطاقة تمكنه من سداد الدفعات المستحقة للشركة من
                    خلال أي من مكن الص ارف الالي او نقاط الدفع الالكترونية كما ستوفر البطاقة أيضا إمكانية الاستخدام في سداد كافة
                    المدفوعات سواءالحكومية وغير الحكومية بما فيها سداد الفواتير او إتمام عمليات الشراء بشكل الكتروني.
                </p>

                <p>
                    <b> رابعاً : </b>

                    له الاستفادة من عدد من أدوات تنظيم و إدارة الاعمال الرقمية التي تتيح له إدارة وتسجيل مبيعاته، ومتابعة حسابات
                    عملائه غير
                    مكتمل سدادها، مع إدارة تدفقاته النقدية، كذلك يتيح التطبيق عدد من الدراسات والمقالات التوعوية والتعليمية
                    لتحسين الوعي المالي
                    ورفع الثقافة المالية والتجارية.
                </p>

                <p>
                    <b> خامساً : </b>
                    نظام الترويج الشبكي، تتيح هذه الخدمة للطرف الثاني عرض معلوماته، بضائعة ومنتجاته على التطبيق المعد من جانب
                    الشركة، والذي يعمل على ايصاله بشبكة من تجار التجزئة الراغبين في الاستفادة من خدماته

                </p>

                <p>
                    <b> سادساً : </b>
                    نظام إدارة التدفقات النقدية والتحصيل الالكتروني من خلال نقطة بيع الكترونية – POS، والتي تقوم الشركة بتوفيرها
                    للتجار دون مقابل وتمكن هذه الخدمة التاجر من توفير حلول دفع ميسرة لعملائه، وبيع بالأجل للمتعاملين معه من خلال
                    استخدام التطبيق المعد من جانب الشركة الذي يتيح الية لتغطية الحسابات المدينة لتوفير السيولة، كما تقوم الشركة
                    بتوفير خدمات التحصيل الالكتروني للمستحقات المالية من خلال قنوات الدفع الخاصة بالشركة.
                </p>

                <li>الترخيص </li>

                <p>
                    تمنحك الشركة، بشرط أن تمتثل لهذه(" <b>الشروط </b>")، ترخيصًا محدودًا غير حصري لا يمكن ترخيصه من الباطن أو
                    نقله إلى شخص آخر وقابل للإلغاء من أجل:

                    <ol>
                        <li>الوصول إلى التطبيق واستخدامه عبر جهازك المحمول و / أو جهاز الحاسوب فقط لاستخدامك للخدمات

                        </li>
                        <li>الوصول إلى أي من المحتويات والمعلومات والمواد ذات الصلة بها، والتي يتم توفيرها من خلال هذه الخدمات،
                            واستخدامها، في جميع الأحداث للاستخدام الشخصي وغير التجاري. تحتفظ الشركة ومرخصيها بأي حقوق غير ممنوحة
                            صراحة في هذه (" <b>الشروط </b>") .
                        </li>
                    </ol>

                </p>

                <li>القيود</li>

                <p>
                    لا يحق لك:
                    <ol>
                        <li>
                            إزالة أي حقوق نشر أو علامات تجارية أو إشعارات ملكية أخرى من أي جزء من الخدمات
                        </li>
                        <li>
                            إعادة إنتاج أو تعديل أو إنشاء أعمال مشتقة أو توزيع أو ترخيص أو تأجير أو بيع أو إعادة بيع أو نقل ملكية أو
                            عرض أو تنفيذ أو نقل أو تعميم أو استغلال الخدمات بشكل عام، في كل حالة، باستثناء ما هو مسموح به صراحةً من
                            قبل الشركة؛
                        </li>
                        <li> معالجة او التلاعب بالرم وز أو إجراء هندسة عكسية أو تفكيك، باستثناء ما تسمح به القوانين المعمول بها

                        </li>
                        <li>إنشاء رابط لأي جزء من الخدمات، أو عمل نسخ طبق الأصل منه أو عمل إطار منها

                        </li>
                        <li> خلق أو إنشاء أي برامج أو نصوص بغرض إ زالة أو فهرسة أو مسح أو استغلال أي جزء من الخدمات بطريقة غير صحيحة
                            أو زيادة التحميل أو تعطيل عمل و / أو وظيفة أي خدمات
                        </li>
                        <li> محاولة الدخول في وصول غير مصرح به إلى الخدمات أو تدمير أي من جوانبها أو أي من الأنظمة والشبكات ذات
                            الصلة.
                        </li>
                    </ol>
                </p>

                <li>تقديم الخدمات</li>

                <p>
                    يقر العميل بأنه قد يتم توفير أجزا ء من الخدمات تحت العلامات التجارية المختلفة لـ آجل أو طلب خيارات الخدمات
                    المرتبطة بخدمات الشركة. يقر العميل أيضًا بأنه يجوز تقديم الخدمات بموجب هذه العلامات التجارية أو خيارات الطلب
                    من قبل أو فيما يتعلق بما يلي:
                </p>

                <ol>
                    <li>
                        بعض الشركات التابعة والشركات التابعة لـ آجل؛ أو
                        أجل نفسها ("الشركة").
                    </li>
                </ol>

                <p>
                    قد يتضمن هذا الموقع خطوط اتصال وروابط بمواقع طرف ثالث على الشبكة. هذه المواقع المترابطة ليست تحت سيطرة
                    الشركة ولا تتحمل الشركة مسؤولية محتويات أي من هذه المواقع المتصلة أو عن أي خط اتصال موجود في أي من المواقع
                    المتصلة. توفر الشركة خطوط الاتصال هذه فقط لغرض تسهيل الوصول إلى المواقع، ولا يعني إنشاء خط اتصال أو اربط أن
                    تتعرف الشركة على الموقع المرتبط بموقعها على الويب.

                </p>

                <li> خدمات ومحتوى الأطراف الثالثة </li>

                <p>
                    قد يتم توفير الخدمات أو إتاحتها بالاقتران مع خدمات ومحتوى طرف ثالث لا يخضع لسيطرة الشركة. تقر الشركة أن شروط
                    الاستخدام وسياسات الخصوصية المختلفة قد تنطبق على استخدامك لخدمات ومحتوى الطرف الثالث. لا توافق الشركة على
                    خدمات ومحتوى الأطراف الثالثة ولا تتحمل بأي شكل من الأشكال المسؤولية تجاه أي من منتجات أو خدمات مزودي الخدمة.
                    بالإضافة إلى ذلك، تعتبرApple أوGoogle أوMicrosoft Corporation أوBlackBerry Limited و / أو الشركات التابعة
                    لها مستفيدين خارجيين من هذا العقد، إذا قمت بالوصول إلى الخدمات باستخدام التطبيقات المصممة خصيصًا للأجهزة
                    المحمولة التي تعمل بنظام Android ،Apple iOS أو Microsoft Windows أو Blackberry على التوالي. هؤلاء المستفيدون
                    الخارجيون ليسوا أطرافًا في هذا العقد وليسوا مسؤولين عن تقديم الخدمات أو دعمها بأي طريقة كانت يخضع وصولك إلى
                    الخدمات باستخدام هذه الأجهزة للشروط المنصوص عليها في الشروط والأحكام المعمول بها للمستفيد الخارجي.
                </p>

                <li>الملكية</li>

                <p>
                    تظل الخدمات وجميع الحقوق المتعلقة بها ملكية حصرية لـ آجل أو المرخصين التابعين لها. لا تمنحك هذه الشروط
                    واستخدامك للخدمات أي حقوق:

                    <ol>
                        <li>

                            فيما يتعلق بالخدمات بخلاف الترخيص المحدود الممنوح أعلاه
                        </li>
                        <li>

                            لاستخدام أو الإشارة إلى أسماء الشركات أو الشعارات أو أسماء المنتجات أو الخدمات أو العلامات التجارية أو
                            علامات الخدمة الخاصة بـ آجل أو تلك الخاصة بمرخصيها.
                        </li>
                    </ol>

                </p>

                <li>حسابات المستخدمين</li>

                <p>
                    من أجل استخدام الخدمات، يجب عليك التسجيل للحصول على حساب شخصي فعال لخدمات المستخدم والحفاظ عليه، ("الحساب")
                    يجب أن يكون عمرك 18 عامًا على الأقل حتى يكون لديك حساب. يتطلب تسجيل الحساب تقديم بيانات شخصية معينة إلى
                    الشركة،بما في ذلك على سبيل المثال لا الحصر، الاسم الأول والأخير، والعنوان ورقم الهاتف المحمول والعمر(<mark>
                        يرجى
                        الرجوع إلى سياسةالخصوصية للحصول على مزيد من المعلومات في هذا الصدد</mark>). أنت توافق، من جانبك، على
                    تسجيل معلومات
                    دقيقة وكاملة وحديثة والاحتفاظ بها في حسابك الخاص. قد يؤدي عدم وجود معلومات دقيقة وكاملة وحديثة عن حسابك إلى
                    عدم قدرتك على الوصول إلى هذه الخدمات أو استخدامها، أو إنهاء الشركة لهذه الخدمات. أنت تتحمل مسؤولية أي نشاط
                    يتم إجراؤه باسم حسابك وتوافق على الحفاظ على أمان اسم المستخدم وكلمة المرور لحسابك في جميع الأوقات. لا يحق لك
                    امتلاك أكثر من حساب ما لم تحصل على إذن كتابي مسبق من الشركة في هذا الصدد. علاوة على ذلك.
                </p>

                <li>متطلبات وسلوكيات المستخدم </li>

                <p>
                    لا يجوز لك تعيين أو نقل حسابك إلى أي شخص أو كيان آخر. أنت توافق على الامتثال لجميع القوانين المعمول بها عند
                    استخدام الخدمات ولا يجوز لك استخدامها إلا للأغراض القانونية. عند استخدام الخدمات، لا يجوز لك التسبب في أي
                    ضرر أو إزعاج أو مضايقة أو ضرر للممتلكات، سواء للمستخدمين الآخرين أو لأطراف ثالثة. في بعض الحالات، قد يطلب
                    منك تقديم بطاقه رقم قومي للوصول إلى الخدمات أو استخدامها، وتوافق على أنه قد يتم رفض وصولك إلى الخدمات أو
                    استخدامها إذا رفضت تقديم بطاقه رقم قومي.
                </p>

                <li>رسائل البريد الإلكتروني وإرسال الإخطارات </li>

                <p>
                    بمجرد إنشاء الحساب، فإنك توافق على أنه يجوز للخدمات أن ترسل لك بعض رسائل البريد الإلكتروني المعلوماتية
                    وارسال الإخطارات كجزء من سياق العمل العادي لاستخدامك للخدمات. لديك الحق في إلغاء الاشتراك، عبر خيار "إلغاء
                    الاشتراك"، من تلقي رسائل البريد الإلكتروني هذه، ويجوز لك تعطيل الإشعارات هذه عبر الخدمات في أي وقت. أنت تقر
                    بأن رفض تلقي رسائل البريد الإلكتروني المعلوماتية والإشعارات الفورية قد يؤثر على استخدامك للخدمات.
                </p>

                <li> رموز ترويجية </li>

                <p>
                    يجوز لشركة آجل، وفقًا لتقديرها المطلق، إنشاء رموز ترويجية يمكن استردادها وإضافتها إلى الحساب أو المزايا أو
                    الامتيازات الأخرى المتعلقة بالخدمات و / أو خدمات الشركة، مع مراعاة أي شروط إضافية تضعها الشركة على أساس كل
                    رمز ترويجي ("الرموز الترويجية") يوافق العميل على أن الرموز الترويجية:

                    <ol>
                        <li>

                            يجب استخدامها للجمهور وللغرض المقصود وبطريقة قانونية
                        </li>
                        <li>

                            لا يجوز نسخها أو بيعها أو نقلها بأي طريقة أو إتاحتها للجمهور (سواء تم نشرها في شكل عام أو غير ذلك) ما لم
                            يتم الاتفاق صراحة من قبل الشركة
                        </li>
                        <li>

                            يجوز تعطيلها من قبل الشركة في أي وقت ولأي سبب دون تحمل أي مسؤولية قانونية عليها
                        </li>
                        <li>

                            لا يمكن استخدامها إلا وفقًا للشروط الخاصة التي وضعتها آجل لكل رمز ترويجي
                        </li>
                        <li>

                            قد تنتهي صلاحيتها قبل أن يستخدمها العميل.
                        </li>
                    </ol>

                    تحتفظ شركة آجل بحقها في سحب أو خصم الأرصدة أو المزايا الإضافية أو الامتيازات الأخرى التي تم الحصول عليها من خلال
                    استخدام الرموز الترويجية من قبل العميل أو من قبل أي عميل آخر إذا قررت الشركة أو اعتقدت أن استخدام أو استرداد
                    الرموز الترويجية كان خاطئًا أو احتياليًا أو غير قانوني أو ينتهك شروط وأحكام هذه الرموز الترويجية .
                </p>

                <li> المحتوى المقدم من المستخدم </li>

                <p>
                    يحق لـ آجل، وفقًا لتقديرها المطلق، السماح لك بإرسال أو تحميل أو نشر أو تقديم معلومات نصية و / أو صوتية و /
                    أو مرئية إلى الشركة و / أو مقدم الخدمة من خلال تقديم الخدمات، بما في ذلك التعليقات وردود الافعال على
                    الخدمات، وإنشاء طلبات الدعم، وتقديم مدخلات في المسابقات والعروض الترويجية ("محتوى المستخدم"). يظل أي محتوى
                    تم إنشاؤه بواسطة المستخدم والذي قدمته ملكًا لك. ومع ذلك، بمجرد تقديم المحتوى الذي ينشئه المستخدمون إلى
                    الشركة، فإنك تمنح الشركة ترخيصًا عالميًا ودائمًا وغير قابل للإلغاء وغير قابل للتحويل وخالي من حقوق الملكية،
                    بالإضافة إلى الحق في الترخيص والاستخدام والنسخ والتعديل وإنشاء أعمال مشتقة وتوزيع، وعرض وتنفيذ محتوى
                    المستخدم بشكل علني واستغلاله بأي طريقة أخرى في جميع الأشكال وقنوات التوزيع المعروفة حاليًا أو التي تم
                    إنشاؤها لاحقًا (بما في ذلك ما يتعلق بخدمات وأعمال الشركة وكذلك مواقع وخدمات الأطراف الثالثة)، دون إرسال
                    المزيد من الإشعارات لك أو الحصول على موافقة من جانبك ودون الحاجة إلى الدفع لك أو لأي شخص أو كيان آخر .

                    <b> تتعهد بما يلي: </b>

                    <ol>
                        <li>
                            تكون انت إما المالك الحصري والوحيد لجميع محتوى المستخدم أو لديك جميع الحقوق التراخيص والموافقات
                            والإعفاءات اللازمة لمنح ترخيص لـ آجل باستخدام محتوى المستخدم على النحو المبين أعلاه
                        </li>
                        <li>

                            أنت توافق على عدم إرسال أي محتوى مستخدم تشهيري أو يحث علي الكراهية أو فاحش أو إباحي أو غير قانوني أو
                            مسيء بطريقة او بأخرى، على النحو الذي تحدده الشركة وفقًا لتقديرها المطلق، سواء كانت هذه المواد محمية
                            بموجب القانون أم لا. كما يجوز لـ آجل مراجعة أو مراقبة أو إزالة أي محتوى مستخدم وفقًا لتقديرها الخاص في
                            أي وقت ولأي سبب من الأسباب مع عدم التزام آجل بالحصول على موافقتك او موافقة أي طرف ثالث على ذلك.
                        </li>
                    </ol>
                </p>

                <li>الوصول إلى الشبكة والأجهزة : </li>

                <p> ستكون مسؤولاً عن الحصول على البيانات اللازمة للوصول إلى الشبكة لاستخدام الخدمات. إذا قمت بالوصول إلى الخدمات
                    أو استخدامها من جهاز محمول، فقد يتم تحصيل رسوم وتكاليف الخدمات والرسائل التي تطبقها شبكة الهاتف المحمول
                    الخاصة بك؛ أنت مسؤول عن دفع هذه الأسعار والرسوم. ستكون مسؤولاً عن الحصول وتحديث الأجهزة المناسبة اللازمة
                    للوصول إلى الخدمات واستخدامها وأي تحديثات لها واستخدامها. لا تضمن الشركة أن كامل الخدمات، أو أي جزء منها،
                    سيعمل على أي أجهزة أو أجهزة معينة. بالإضافة إلى ذلك، قد تعاني الخدمات من أعطال وتأخيرات ناتجة عن استخدام
                    الإنترنت والاتصالات الإلكترونية.
                </p>

                <li> سياسة الخصوصية: </li>

                <div>
                    <p>
                        تنطبق سياسة الخصوصية على وجودك واستخدامك لجميع الخدمات التي توفرها شركة آجل أو الشركات التابعة لها ("خدمات
                        آجل") من خلال التطبيق الرقمي و/او صفحة الويب الخاصة بنا بما في ذلك على سبيل المثال لا الحصر (رابط الموقع
                        الإلكتروني الداخلي) www.agel.com.eg
                        توضح سياسة الخصوصية هذه المعلومات التي نتلقاها منك، وكيف نتعامل مع معلوماتك ونفصح عنها، والخطوات التي نتخذها
                        لحماية معلوماتك. باستخدام الخدمات، فإنك توافق على ممارسات الخصوصية الموضحة في هذه السياسة.

                    </p>

                    <b> المعلومات التي نتلقاها: </b>

                    <p>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - معلومات شخصية</b>
                    </p>

                    <p>
                        عند استخدامك للخدمات، يمكنك تقديم وقد نطلب معلومات عنك أو يمكن استخدامها لتحديد هويتك، بما في ذلك اسمك
                        وعنوان بريدك الإلكتروني وعنوانك البريدي ورقم هاتفك المحمول والعمر والجنس وتاريخ الميلاد. على سبيل المثال لا
                        الحصر، يمكنك تزويدنا بالمعلومات الشخصية عند التسجيل للحصول على حساب الشركة، وعند ملء النماذج أو الحقول على
                        الموقع، وعند المشاركة في الاستطلاعات عبر الإنترنت، وعندما تتصل بنا أو تطلب معلومات عنا، فإن هذا الموقع
                        وخدمتنا (سواء عن طريق البريد الإلكتروني أو أي وسيلة أخرى).
                        عند استخدامك للخدمات، يمكنك تقديم وقد نقوم بجمع معلومات معينة بما في ذلك رقمك القومي، حجم اعمالك، والتغطية
                        التأمينية الحالية وأنواع أخرى مماثلة من المعلومات.
                        عندما تستخدم خدمات معينة (مثل طلب الاشتراك في نظام المرابحة التجارية)، فإنك تفوض شركة آجل بمشاركة معلوماتك
                        مع أطراف ثالثة معينة نيابة عنك وتفويض هذه الأطراف الثالثة.
                        يشار إلى المعلومات السابقة باسم
                        <b>"المعلومات الشخصية" </b>.
                    </p>

                    <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - معلومات ملفات تعريف الارتباط (كوكيز)</b>

                    <p>
                        عند استخدام الخدمات، قد نرسل ملف تعريف ارتباط واحدًا أو أكثر (وهي ملفات نصية صغيرة تحتوي على سلسلة من الأحرف
                        الأبجدية الرقمية) إلى جهاز الحاسوب أو الجهاز المحمول الخاص بك. قد نستخدم ملفات تعريف الارتباط للجلسة وملفات
                        تعريف الارتباط الدائمة. يختفي ملف تعريف ارتباط الجلسة بعد إغلاق المتصفح. يظل ملف تعريف الارتباط الدائم بعد
                        إغلاق المتصفح الخاص بك ويمكن أن يستخدمه متصفحك في الزيارات اللاحقة للخدمات. يمكن إزالة ملفات تعريف الارتباط
                        الدائمة. يرجى مراجعة متصفح الويب الخاص بك، ملف "المساعدة" لمعرفة الطريقة الصحيحة لتعديل إعدادات ملفات تعريف
                        الارتباط الخاصة بك. إذا قمت بحذف ملفات تعريف الارتباط من الخدمات أو اخترت عدم قبولها، فقد لا تتمكن من
                        استخدام ميزات الخدمات إلى أقصى امكانياتها.
                    </p>

                    <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - المعلومات التي يتم جمعها تلقائيًا</b>

                    <p>
                        عندما تزور التطبيق و/او صفحة الويب أو تستخدم الخدمات أو تفتح بريدًا إلكترونيًا من آجل، فقد نتلقى تلقائيًا
                        ونسجل معلومات معينة من جهاز الكمبيوتر و / أو متصفح الويب و / أو الجهاز المحمول الخاص بك، بما في ذلك عنوان IP
                        الخاص بك أو عنوان أو معرف جهاز آخر، مستعرض الويب و / أو نوع الجهاز، وإعدادات وتكوينات الأجهزة والبرامج،
                        وصفحات الويب أو المواقع التي تزورها قبل أو بعد زيارة صفحة الويب مباشرة، والصفحات التي تشاهدها على موقعنا،
                        وإجراءاتك على صفحة الويب، والتواريخ والأوقات التي تقوم فيها بزيارة الخدمات أو الوصول إليها أو استخدامها.
                        عندما تستخدم صفحة الويب على جهاز محمول، فقد نجمع أيضًا الموقع الفعلي لجهازك، على سبيل المثال، باستخدام
                        إشارات الأقمار الصناعية أو برج الهاتف الخلوي أو إشارات الشبكة المحلية اللاسلكية.
                    </p>

                    <p>
                        <b> كيف نتعامل مع المعلومات التي نتلقاها: </b>
                        معلوماتك هي جزء لا يتجزأ من عملياتنا، ونحن نستخدمها بعدة طرق في تقديم الخدمات وتشغيل أعمالنا. على سبيل
                        المثال:
                    </p>

                    <li>نحن نستخدم المعلومات التي تقدمها لتشغيل وصيانة وتعزيز وتوفير جميع ميزات الخدمات ولتمكين الأطراف الثالثة
                        من تقديم خدمات إضافية لك.
                    </li>
                    <li>نستخدم المعلومات التي تقدمها لفهم وتحليل اتجاهات الاستخدامات وتفضيلات مستخدمينا عبر أجهزة مختلفة، لتحسين
                        الخدمات، وإنشاء ميزات جديدة، وتحسين الكشف عن الاحتيال وأمن المعلومات.
                    </li>
                    <li>
                        قد نستخدم عنوان بريدك الإلكتروني أو معلومات شخصية أخرى
                        <p> (أ) للاتصال بك لأغراض إدارية مثل خدمة العملاء </p>
                        <p> (ب) لمعالجة قضايا الملكية الفكرية أو حق الخصوصية أو التشهير المتعلقة بالمحتوى الذي نشرته على الخدمات
                            و / أو
                        </p>
                        <p> (ج) لإرسال مواد ترويجية و / أو عروض و / أو رسائل إليك تتعلق بالخدمات وأنشطة الأطراف الثالثة
                            التي نعمل معها.
                        </p>
                    </li>

                    <li>
                        قد نستخدم معلومات ملفات تعريف الارتباط والمعلومات المجمعة تلقائيًا من أجل:
                        <p> (أ) تخصيص خدماتنا، مثل تذكر معلوماتك حتى لا تضطر إلى إعادة إدخالها أثناء زيارتك أو في المرة التالية التي
                            تزور فيها الخدمات </p>
                        <p>
                            (ب) توفير إعلانات ومحتوى ومعلومات مخصصة عبر أجهزتك
                        </p>
                        <p>
                            (ج) مراقبة وتحليل فعالية الخدمات وأنشطة تسويق الطرف الثالث
                        </p>
                        <p>
                            (د) مراقبة مقاييس استخدام الموقع الإجمالية مثل إجمالي عدد الزوار والصفحات التي تمت مشاهدتها؛
                        </p>
                        <p>
                            (هـ) تتبع إدخالاتك وعمليات الإرسال والحالة في أي عروض ترويجية أو أنشطة أخرى.
                        </p>
                    </li>

                    <p>
                        <b> عندما نشارك معلوماتك: </b>
                        قد نكشف عن معلوماتك فيما يتعلق بتوفير الخدمات وتشغيل أعمالنا. على سبيل المثال:
                    </p>

                    <li>
                        قد نشارك المعلومات الشخصية مع بعض الأطراف الثالثة التابعة لنا للتحقق من هويتك فيما يتعلق باستخدامك لجوانب
                        معينة من الخدمات.
                    </li>

                    <li>
                        على سبيل المثال، قد نطلب الرقم القومي الخاص بك، أو جزء منه، للتحقق من هويتك.


                    </li>

                    <li>
                        يجوز لنا مشاركة المعلومات الشخصية مع موفري الخدمات الخارجيين فيما يتعلق باستخدامك لجوانب محددة من الخدمات.
                        تتضمن بعض الأمثلة على سبيل المثال لا الحصر:
                    </li>

                    <li> عندما تطلب ردودًا من خلال خدماتنا، قد تقدم آجل بعض المعلومات الخاصة بك لخبراء ومحاميين ومستشارين من أطراف
                        ثالثة.
                    </li>

                    <li>
                        عندما تطلب التواصل مع شركة أو كيان تجاري من المسجلين على التطبيق، ستوفر آجل بعض المعلومات الخاصة بك لمقدمي
                        خدمات الطرف الثالث.
                    </li>

                    <li>
                        عندما تقوم بالإبلاغ عن شكوى بخصوص منتج أو مزود خدمة تابع لجهة خارجية واردة على التطبيق الخاص بنا.

                    </li>

                    <li>
                        من خلال تقديم طلب من خلال خدماتنا، فإنك توافق على الكشف عن معلوماتك لأطراف ثالثة حتى نتمكن من تنفيذ طلبك.
                        يخضع جمع المعلومات الخاصة بك واستخدامها من قبل جهات خارجية لسياسة الخصوصية وشروط الاستخدام الخاصة بكل طرف
                        ثالث. لا تتحكم الشركة في الوسائل التي تستخدم بها الأطراف الثالثة معلوماتك أو تفصح عنها.
                    </li>

                    <p>
                        إذا قمت بتقديم طلب من خلال خدماتنا، يجوز لـ آجل و / أو مزود خدمة الطرف ثالث الاتصال بك مباشرة لتقديم عروض
                        الأسعار أو المعلومات عبر الهاتف و / أو الفاكس و / أو البريد الإلكتروني.
                        لا ترسل طلبًا للحصول على معلومات أو خدمات إذا كنت لا تريد مشاركة معلوماتك الشخصية كما هو موضح أعلاه. بمجرد
                        إرسال طلب عبر الخدمات ومشاركة معلوماتك كما هو موضح أعلاه، ستنطبق شروط سياسة الخصوصية الخاصة بالطرف الثالث
                        على استخدام هذا الطرف لمعلوماتك.
                    </p>

                    <li>
                        نحن نعمل مع مزودي خدمات من جهات خارجية لتوفير خدمات الموقع الإلكتروني وتطوير التطبيقات والاستضافة وتخزين
                        البيانات والصيانة وغيرها من الخدمات لنا. إلى الحد الذي يكون من الضروري لمقدمي الخدمات الخارجيين هؤلاء إكمال
                        التزاماتهم التعاقدية تجاهنا، قد تتمكن هذه الأطراف الثالثة من الوصول إلى معلوماتك.
                    </li>

                    <li>
                        نوفر معلومات معينة لأطراف ثالثة، من أجل :

                        <ol>
                            <li>
                                الامتثال لالتزامات إعداد التقارير المختلفة </li>
                            <li>
                                لأغراض تجارية أو تسويقية </li>
                            <li>
                                لمساعدة هذه الأطراف في فهم اهتمامات مستخدمينا وعاداتهم وأنماط استخدامهم لبعض البرامج والمحتوى
                                والخدمات والإعلانات والعروض الترويجية و / أو الوظائف المتاحة من خلال الخدمات. عندما يكون ذلك ممكنًا،
                                نقدم هذه المعلومات بطريقة مجهولة الهوية.
                            </li>
                        </ol>

                    </li>

                    <li>
                        قد نكشف عن معلوماتك إذا طُلب منك ذلك بموجب القانون أو بحسن نية أن مثل هذا الإجراء ضروري للامتثال للقوانين
                        واللوائح (مثل قانون حقوق الطبع والنشر)، استجابةً لأمر محكمة أو أمر قضائي أو حكومي آخر.
                    </li>

                    <li>
                        قد نكشف عن معلوماتك وفقًا للقوانين وكما هو مطلوب من قبل السلطات لحماية الشركة من الاستخدامات أو الأنشطة
                        الاحتيالية أو المسيئة أو غير القانونية؛ للتحقيق والدفاع عن أنفسنا ضد أي ادعاءات أو مزاعم من طرف ثالث؛
                        لمساعدة وكالات الإنفاذ الحكومية؛ لحماية أمن أو سلامة الخدمات؛ أو لحماية الحقوق أو الممتلكات أو السلامة
                        الشخصية لـ آجل أو مستخدمينا أو غيرهم.
                    </li>

                </div>

                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - التزامنا بأمن البيانات:</b>

                <p>
                    نحن نستخدم ضمانات فنية محدده مصممة لحماية سلامة وأمن معلوماتك. ومع ذلك، لا يمكننا ضمان أمن أي معلومات ترسلها
                    إلينا من خلال الخدمات أو المتجر على الموقع وأنت تقوم بذلك على مسؤوليتك الخاصة. لا يمكننا أيضًا ضمان عدم
                    إمكانية الوصول إلى هذه المعلومات أو الكشف عنها أو تغييرها أو إتلافها عن طريق انتهاك أي من الضمانات التقنية
                    الخاصة بنا. لا يمكننا التحكم في تصرفات المستخدمين الآخرين أو الجهات الخارجية الأخرى التي قد تختار مشاركة
                    معلوماتك معها. لذلك، لا يمكننا ولا نضمن أن المعلومات التي تنشرها من خلال الخدمات وعلى التطبيق و/او صفحة
                    الويب لن يتم عرضها بواسطة أشخاص غير مصرح لهم. نحن لسنا مسؤولين عن التحايل على أي إعدادات خصوصية أو إجراءات
                    أمنية واردة في صفحة الويب. حتى بعد الإزالة، قد تظل نسخ المعلومات التي نشرتها قابلة للعرض في الصفحات المخبأة
                    والأرشفة أو إذا قام المستخدمون الآخرون بنسخ هذه المعلومات أو تخزينها.
                    <br />
                    <b> حقوقك: </b>
                    <br />
                    يجوز لك ولديك الحق في الامتناع عن الموافقة المسبقة للاحتفاظ ببياناتك الشخصية أو معالجتها، أو تصحيح بياناتك
                    الشخصية أو تعديلها أو محوها أو إضافتها أو تحديثها، أو تخصيص معالجة بياناتك الشخصية لنطاق محدد، كن على علم
                    بأي خرق أو انتهاك بياناتك الشخصية، والاعتراض على معالجة بياناتك الشخصية أو نتائجها عندما تتعارض مع حقوقك
                    وحرياتك الأساسية.
                </p>

                <li> خلاء المسؤولية وحدود المسؤولية والتعويض</li>

                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - اخلاء المسئولية:</b>

                <p>
                    يتم توفير خدمات آجل "حسب حالتها الحالية" وتوافرها. تُخلي آجل مسئوليتها عن أي اتفاقيات أو إقرارات ضمنية أو
                    صريحة أو تشريعية غير منصوص عليها صراحة هنا بما في ذلك الإقرارات الضمنية بقابلية التسويق والملاءمة لغرض معين
                    وعدم الانتهاك. بالإضافة إلى ذلك، لا تقدم آجل أي إقرارات أو اتفاقيات أو ضمانات بدقة التوقيت والجودة والملاءمة
                    وتوافر جميع الخدمات، أو أي جزء منها، أو التغطيات المطلوبة أثناء إكمال هذه الخدمات. وبالمثل، لا تقدم آجل أيا
                    من التأكيدات المذكورة أعلاه لتعطيل الخدمات أو لكونها خالية من الأخطاء. ومع ذلك، تضمن الشركة أن جميع مقدمي
                    الخدمات الموجودين على التطبيق وصفحة الويب الخاصة بها مرخصون ومتوافقون تمامًا مع قوانين جمهورية مصر العربية
                    التي تنظم أداء الخدمات المقدمة من قبلهم، ولكنها لا تضمن سلامتها وأهليتها. يجب أن توافق على أن جميع المخاطر
                    الناشئة عن استخدامك للخدمات أو أي جزء منها، إلى الحد الذي تسمح به القوانين المعمول بها، هي التي تتحملها
                    وحدك. علاوة على ذلك، يوافق مقدمي الخدمة على أنك تتحمل وحدك جميع المخاطر الناتجة عن عملية تقديم الخدمة.
                </p>

                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- نطاق المسؤولية</b>

                <p>
                    لا تتحمل آجل المسؤولية عن أي أضرار غير مباشرة أو عرضية أو نموذجية أو عقابية أو تبعية بما في ذلك الربح أو
                    فقدان البيانات أو الإصابة على مستوى الاشخاص أو أضرار الممتلكات المتعلقة بأي وسيلة لاستخدام الخدمة. يتم إخلاء
                    المسؤولية عن هذا الامر حتى لو تم إخطار آجل بذلك. لن تكون الشرة مسؤولة عن أي أضرار أو التزامات أو خسائر ناتجة
                    عن:

                    <ol>
                        <li>
                            استخدامك أو اعتمادك أو عدم قدرتك على الوصول إلى الخدمات أو استخدامها.
                        </li>
                        <li>
                            أي معاملة تجارية أو علاقة بين المستخدمين وأي مقدم خدمة حتى لو تم إخطار آجل بإمكانية حدوث مثل هذه
                            الأضرار. لن تكون لآجل مسؤولة عن أي تأخير أو أداء غير ملائم ناتج عن أسباب خارجة عن سيطرة آجل المعقولة.
                        </li>
                    </ol>
                    لا يجوز تفسير أن قيود بنود إخلاء المسؤولية والمسؤولية المذكورة في هذه السياسة تهدف إلى تقليل المسؤولية أو
                    استبدال حقوق المستخدم. لا تخضع هذه المسؤولية لأي استثناء وفقًا للقانون المعمول به.
                </p>

                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- التعويض</b>

                <p>
                    يجب أن توافق على التأكد من أن آجل وجميع مسؤوليها ومديريها وموظفيها ووكلائها سيتم إبراء ذمتهم وتعويضهم عن
                    جميع المطالبات والطلبات وتحمل الخسائر والنفقات بما في ذلك، أتعاب المحاماة) التي ينتج عنها / من أي مما يلي :

                    <ol>
                        <li>
                            استخدامك للخدمات و / أو التطبيق، أو العناصر المقدمة لك عند استخدام الخدمات </li>
                        <li>
                            انتهاكك أو خرقك للشروط بموجب هذا العقد

                        </li>
                        <li>

                            استخدام محتوى ملف محتوي المستخدم الخاص بك بواسطة آجل
                        </li>

                        <li>
                            انتهاك العميل لحقوق أي طرف ثالث بما في ذلك مقدم الخدمة (مع الأخذ في الاعتبار أن مقدم الخدمة ليس أحد
                            موظفي آجل).

                        </li>
                    </ol>
                </p>

                <li>
                    القوانين المعمول بها وتسوية النزاعات.

                </li>

                <p>
                    ما لم ينص على خلاف ذلك هنا، تخضع جميع هذه الشروط لقوانين جمهورية مصر العربية. يجب تسوية جميع النزاعات أو
                    الخلافات أو الدعاوى القضائية أو النزاعات التي قد تنشأ عن هذه الخدمات أو الشروط أو ذات الصلة بها، بما في ذلك
                    فعاليتها وتفسيرها وتطبيقها ("النزاع") بشكل إلزامي من خلال رسالة بريد إلكتروني مرسلة إلى <mark>
                        (cs@agel.io)</mark> تُفصّل النزاع.
                    يحق لـ آجل طلب ملفات ووثائق إضافية من المستخدم لتسوية النزاع. إذا لم تتم تسوية هذا النزاع في غضون ستين (60)
                    يومًا بعد تقديم طلب تسوية النزاع، فسيتم الرجوع إلى التحكيم وفقًا لقواعد وأحكام مركز القاهرة الإقليمي للتحكيم
                    التجاري الدولي (CRCICA)، القاهرة، جمهورية مصر العربية.
                </p>

                <li>
                    دعاوى انتهاك حقوق الملكية
                </li>

                <li>
                    الإخطارات
                </li>

                <p>
                    قد ترسل آجل إخطارات بخصوص الخدمات عبر البريد الإلكتروني أو عن طريق اشعارات على التطبيق الخاص بها. يمكنك
                    إخطار آجل عبر مراسلات مكتوبة إلى [www.agel.com.eg].
                </p>

                <li>
                    أحكام عامة
                </li>

                <p>
                    لا يجوز لك، كليًا أو جزئيًا، التنازل عن هذه الشروط أو نقلها دون موافقة مسبقة من آجل، ومع ذلك، فإنك توافق على
                    منح آجل موافقتك لتمكينها، جزئيًا أو كليًا، من التنازل عن هذه الشروط وتحويلها إلى:

                    <ol>
                        <li>
                            أي شركة فرعية أو شركة تابعة.
                        </li>
                        <li>
                            - أحد مساهمي آجل أو أصحاب الأعمال أو الأصول المرتبطين بها.
                        </li>
                        <li>
                            أي شركة لاحقة في حالة الاندماج. أنت لست في أي مشروع مشترك أو شراكة أو أي علاقة توظيف أو تمثيل بينك وبين
                            آجل، أو بين العميل وأي مقدم خدمة آخر وفقًا للعقد المبرم بينك وبين آجل أو وفقًا لاستخدامك للخدمات.
                        </li>
                    </ol>

                    في حالة ما إذا كان أي نص وارد في هذه الاتفاقية غير صالح أو غير قانوني أو غير قابل للتنفيذ كليًا أو جزئيًا أو
                    بموجب أي قانون، فلن يكون هذا النص جزءًا من هذه الاتفاقية، ولا يجوز بأي حال من الأحوال أن تكون صلاحية وشرعية
                    وقابلية تنفيذ النصوص المتبقية تتأثر أو تضعف بذلك ولن يكون هذا النص ساريًا إلا في حدود عدم الصلاحية أو عدم
                    الشرعية أو عدم قابلية التنفيذ.
                    <br />
                    في مثل هذه الحالة، يجب على كلا الطرفين استبدال كامل النص غير القانوني أو غير الصالح أو غير القابل للتنفيذ أو جزء
                    منه بنص قانوني وصالح وقابل للتطبيق أو جزء منه بشرط أن يكون لهذا النص / جزء منه، إلى أقصى حد ممكن، تأثير مماثل
                    إلى ذلك النص غير الصالح أو غير القانوني أو غير القابل للتنفيذ أو جزء منه، مع مراعاة المحتوى والغرض منه.
                    <br />
                    تشكل هذه الشروط والاحكام مجمل الاتفاق والتفاهم المتبادل بين الطرفين فيما يتعلق بموضوع هذه الاتفاقية. كما تحل هذه
                    الشروط محل جميع الاتفاقيات السابقة والحالية وتحل محلها. تشير عبارة "بما في ذلك" المذكورة هنا إلى "بما في ذلك على
                    سبيل المثال لا الحصر".
                </p>

            </ul>

        </Container>
    )
}